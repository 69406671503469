import { NavLink } from "react-router-dom";
import Container from "../components/Container";
import { Bars3Icon } from "@heroicons/react/24/solid";
import { useState } from "react";

const NavbarOutSide = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const hideMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  if (isMenuVisible) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }

  return (
    <header
      className={`sm:py-[39px] py-[30px] px-[30px] absolute top-0 left-0 right-0 z-50 }`}
    >
      <Container>
        <div className="relative">
          <span className="absolute top-0 right-0 shadow-blue-overlay" />
          <nav className="flex items-center  relative">
            <div className="flex-1">
              <NavLink to="/" className="block h-10">
                <a href="https://robowriter.de">
                  <img
                    className="sm:h-[48px] h-[26.953px]"
                    src="/assets/images/logo.svg"
                    alt="logo"
                  />
                </a>
              </NavLink>
            </div>
            <div className="flex-1  xl:flex hidden  items-center justify-end gap-6">
              <NavLink
                to="/sign-in"
                className="inline-flex items-center text-secondary  tracking-[0.15px] font-outfit font-[500] transition-[0.3s] hover:text-[#202356]"
              >
                Einloggen
              </NavLink>
              <NavLink
                to="/sign-up"
                className="inline-flex items-center text-secondary   tracking-[0.15px] font-outfit font-[500] transition-[0.3s] hover:text-[#202356]"
              >
                Meinen Account Erstellen
              </NavLink>
            </div>
            {/* Mobile View */}
            <div className="xl:hidden">
              <div>
                <button className="text-primary-500" onClick={toggleMenu}>
                  <Bars3Icon className="h-8 w-8" />
                </button>
                <div
                  className={`xl:hidden fixed inset-0 transition-[0.3s]  ${isMenuVisible ? "opacity-1 visible" : "opacity-0 invisible"
                    }`}
                >
                  <div
                    onClick={hideMenu}
                    className={`overlay fixed transition-[0.3s]  inset-0 bg-[rgba(65,65,67,0.50)] ${isMenuVisible ? "opacity-1 visible" : "opacity-0 invisible"
                      }`}
                  ></div>

                  <div
                    className={`fixed bottom-0 right-0 top-0 sm:max-w-[400px] max-w-[237px] py-[38px] px-[28px] w-full bg-white z-[9999] transition-[0.3s] ease-out  duration-300 ${isMenuVisible ? "translate-x-0" : "translate-x-full"
                      } `}
                  >
                    <h4 className="text-[15px] font-[800] text-textColor mb-[30px]">
                      Menü
                    </h4>
                    <div className="flex-1 flex flex-col ">
                      <NavLink
                        to="/sign-in"
                        className="inline-flex items-center text-secondary  tracking-[0.15px] font-outfit font-[500] transition-[0.3s] hover:text-[#202356]"
                      >
                        Einloggen
                      </NavLink>
                      <NavLink
                        to="/sign-up"
                        className="inline-flex items-center text-secondary   tracking-[0.15px] font-outfit font-[500] transition-[0.3s] hover:text-[#202356]"
                      >
                        Meinen Account Erstellen
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </Container>
    </header>
  );
};

export default NavbarOutSide;
