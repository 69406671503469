import React from 'react';

const PulsingMessageSkeleton: React.FC = () => {
  return (
    <div className="flex items-start space-x-2 animate-pulse">
      <div className="flex-1 space-y-2">
        <div className="h-4 bg-gray-300 rounded w-3/4"></div>
        <div className="h-4 bg-gray-300 rounded"></div>
        <div className="h-4 bg-gray-300 rounded w-5/6"></div>
      </div>
    </div>
  );
};

export default PulsingMessageSkeleton;
