import { Link } from "react-router-dom";
import MiniSpinningLoader from "../loader/mini_spinning_loader";
// import Dropdown from "./Dropdown";
import {
  firestore,
  collection,
  doc,
  setDoc,
  getDoc,
  auth
} from "../../config/fbConfig";

type Props = {
    id: string
    title: string;
    description: string;
    iconName: string;
    type: string;
    iconColor: string;
    favorite?: boolean;
    disableLink?: boolean;
    loading?: boolean;
    locked?: boolean;
};

const TemplateCard: React.FC<{ items: Props, pinIconDisabled?: boolean }> = ({ items, pinIconDisabled = false }) => {
    const param = decodeURIComponent(items.title).replace(/\s/g, "-");
    const pathname = `/templates/${param}`;


const clickPin = async () => {
    if (auth.currentUser!.uid) {
      // build user & document reference
      const userDocRef = doc(collection(firestore, "users"), auth.currentUser!.uid);
      const writeDataDocRef = doc(collection(userDocRef, "writedata"), 'appdata');
      const allwriteDataRef = await getDoc(writeDataDocRef);
      let allwriteData = allwriteDataRef.data() || {};

      // check if writeDataDocRef exists
      getDoc(writeDataDocRef)
        .then((docSnap) => {
          let templates: any = [];

          if (docSnap.exists()) {
            let data = docSnap.data();
            if (data.hasOwnProperty('pinned_templates')) {
                templates = data.pinned_templates;
            } else {
                templates.push(items.id);
                data.pinned_templates = templates
                return setDoc(writeDataDocRef, data);
            }
          } else {
            // Create a document if doesn't exists with initial data
            templates.push(items.id);
            return setDoc(writeDataDocRef, { pinned_templates: templates });
          }

          return templates;
        })
        .then((templates) => {
          // check if "items.id" is already in the "pinned_templates"
          const index = templates.indexOf(items.id);

          if (index === -1) {
            // itemID is not in the array, insert it
            templates.push(items.id);
          } else {
            // itemID is in the pinned_templates, remove it
            templates.splice(index, 1);
          }

          // Update "pinned_templates"
          allwriteData.pinned_templates = templates
          return setDoc(writeDataDocRef, allwriteData);
        })
        .then(() => {
          // Document successfully updated!
        })
        .catch((error) => {
          // console.error("Error updating document: ", error);
        });
    }

};

    const cardContent = (
        <>
            <div className="relative">
                <div>
                    <div className="w-full flex items-center">

                    <span className="block h-9 w-9 mb-1 sm:mb-4">
                        <span className={`inline-flex items-center p-1 justify-center h-full w-full
                         bg-gradient-to-tr from-[#C06EEC1A] to-[#1754FC1A] rounded-full ${items.loading ? 'bg-gray-500 opacity-50' : ''}`}>
                            {/*{items.disableLink && (*/}
                            {/*    <div role="status"*/}
                            {/*         className="text-center z-10 absolute inset-0 flex items-center justify-center m-8 md:m-16">*/}
                            {/*        <MiniSpinningLoader/>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                            <i className={`${items.iconName} inline-flex items-center justify-center w-auto h-auto text-lg bg-gradient-to-tr from-[#C06EEC] to-[#1754FC] bg-clip-text text-transparent `}
                            ></i>
                        </span>
                    </span>
                        {items.type === "Persönliche Vorlagen" && (
                        <div className="ml-4 w-28 h-6 mb-1 sm:mb-4 flex items-center justify-center hidden md:block">
                            <div
                                className="w-full m-2 h-full text-center bg-gradient-to-tr from-[#C06EEC] to-[#1754FC] rounded text-white text-xs flex items-center justify-center">
                                Eigene Vorlage
                            </div>
                        </div>
                            )}
                    </div>

                    <h4 className={`mb-1 sm:mb-2 md:text-base text-xs font-semibold line-clamp-2 ${items.loading ? 'text-gray-500 opacity-50' : ''}`}>{items.title}</h4>
                    <p className={`md:text-[11px] text-[10px] font-poppins text-gray-8000 md:line-clamp-3 line-clamp-5 ${items.loading ? 'text-gray-500 opacity-50' : ''}`}>{items.description}</p>
                </div>
            </div>

            {/* Locked Template Card */}
            {items.locked && (
                <div className="inset-0 absolute bg-black/50 z-10 flex items-center justify-center p-4">
                    <svg className=" md:w-20 md:h-20 h-11 w-11" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M67.2917 35.4167H70.8333C71.7726 35.4167 72.6735 35.7898 73.3377 36.454C74.0019 37.1182 74.375 38.019 74.375 38.9583V74.375C74.375 75.3143 74.0019 76.2151 73.3377 76.8793C72.6735 77.5435 71.7726 77.9167 70.8333 77.9167H14.1667C13.2274 77.9167 12.3265 77.5435 11.6623 76.8793C10.9981 76.2151 10.625 75.3143 10.625 74.375V38.9583C10.625 38.019 10.9981 37.1182 11.6623 36.454C12.3265 35.7898 13.2274 35.4167 14.1667 35.4167H17.7083V31.875C17.7083 25.2998 20.3203 18.994 24.9696 14.3446C29.619 9.69531 35.9248 7.08334 42.5 7.08334C49.0752 7.08334 55.381 9.69531 60.0304 14.3446C64.6797 18.994 67.2917 25.2998 67.2917 31.875V35.4167ZM60.2083 35.4167V31.875C60.2083 27.1785 58.3426 22.6743 55.0217 19.3533C51.7007 16.0324 47.1965 14.1667 42.5 14.1667C37.8035 14.1667 33.2993 16.0324 29.9783 19.3533C26.6574 22.6743 24.7917 27.1785 24.7917 31.875V35.4167H60.2083ZM38.9583 49.5833V63.75H46.0417V49.5833H38.9583Z" fill="white" />
                    </svg>

                </div>)
            }
        </>
    )
        ;

    return items.disableLink ? (
        <div
            className="block fade-in h-full bg-white text-primary-500 border border-gray-300 shadow p-4 transition-transform duration-300 hover:scale-95 relative">
            {cardContent}
            <div role="status"
                 className="text-center z-10 absolute inset-0 flex items-center justify-center m-8 md:m-12">
                <MiniSpinningLoader/>
            </div>
        </div>
    ) : (
        <div className="relative transition-transform duration-300 hover:scale-95 h-full group">
            {/*<Dropdown />*/}

            {!pinIconDisabled  && 
                <button className={`absolute top-6 right-8 z-[1] md:scale-100 scale-75 ${!items.favorite ? 'hidden group-hover:block' : ''}`} onClick={() => clickPin()}>
                        {
                            (!items.favorite ? !items.favorite : false) ?
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_5_3846)">
                                        <path
                                            d="M10.4948 1.70869C10.842 1.11074 11.6055 0.908098 12.2034 1.25522L20.8518 6.27582C21.4497 6.62295 21.6524 7.38649 21.3052 7.98444C20.9581 8.58239 20.1946 8.78503 19.5966 8.4379L18.6 7.85936L16.0787 13.0895C16.9282 14.4815 17.2549 16.1752 16.9072 17.8478L16.8821 17.9688C16.8014 18.3646 16.5341 18.6927 16.1687 18.8554C15.8032 19.0182 15.3774 18.9969 15.0295 18.7948L4.21908 12.5191C3.87112 12.3171 3.6429 11.9633 3.60157 11.5599C3.56023 11.1565 3.71603 10.7636 4.01774 10.5007L4.11036 10.4189C5.39045 9.28759 7.02321 8.73137 8.6533 8.77885L11.9449 3.99585L10.9483 3.41731C10.3503 3.07018 10.1477 2.30664 10.4948 1.70869ZM7.91567 16.1104L10.0778 17.3656L8.19503 20.6087C7.8479 21.2067 7.08436 21.4093 6.48641 21.0622C5.88846 20.7151 5.68582 19.9515 6.03294 19.3536L7.91567 16.1104Z"
                                            fill="#202356"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5_3846">
                                            <rect width="15" height="20" fill="white"
                                                transform="translate(10.041) rotate(30.1364)"/>
                                        </clipPath>
                                    </defs>
                                </svg> :
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                    <g clipPath="url(#clip0_39_182)">
                                        <path
                                            d="M10.4948 1.70869C10.842 1.11074 11.6055 0.908098 12.2034 1.25522L20.8518 6.27582C21.4497 6.62295 21.6524 7.38649 21.3052 7.98444C20.9581 8.58239 20.1946 8.78503 19.5966 8.4379L18.6 7.85936L16.0787 13.0895C16.9282 14.4815 17.2549 16.1752 16.9072 17.8478L16.8821 17.9688C16.8014 18.3646 16.5341 18.6927 16.1687 18.8554C15.8032 19.0182 15.3774 18.9969 15.0295 18.7948L4.21908 12.5191C3.87112 12.3171 3.6429 11.9633 3.60157 11.5599C3.56023 11.1565 3.71603 10.7636 4.01774 10.5007L4.11036 10.4189C5.39045 9.28759 7.02321 8.73137 8.6533 8.77885L11.9449 3.99585L10.9483 3.41731C10.3503 3.07018 10.1477 2.30664 10.4948 1.70869ZM7.91567 16.1104L10.0778 17.3656L8.19503 20.6087C7.8479 21.2067 7.08436 21.4093 6.48641 21.0622C5.88846 20.7151 5.68582 19.9515 6.03294 19.3536L7.91567 16.1104Z"
                                            fill="url(#paint0_linear_39_182)"/>
                                    </g>
                                    <defs>
                                        <linearGradient id="paint0_linear_39_182" x1="0.124738" y1="14.3858" x2="20.5418"
                                                        y2="11.5607" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#C06EEC"/>
                                            <stop offset="1" stopColor="#1754FC"/>
                                        </linearGradient>
                                        <clipPath id="clip0_39_182">
                                            <rect width="15" height="20" fill="white"
                                                transform="translate(10.041) rotate(30.1364)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                        }

                </button>
            }

            <Link
                to={pathname}
                state={items}
                className={`flex flex-col relative md:min-h-[187px] fade-in h-full border-[1px] border-transparent
        ${(!items.favorite ? !items.favorite : false) ? ' bg-gradient-to-tr from-gradientColor-1000 to-gradientColor-2000' : ' border-gray-300'} text-primary-500 shadow-md rounded-[4px]`}>
                <div
                    className="bg-white w-full h-full pr-[1px] md:px-[19px] md:pt-[18px] md:pb-[22px] p-3.5 rounded-[4px]">
                    {cardContent}
                </div>
            </Link>
        </div>

    );
};

export default TemplateCard;
