import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { auth } from "../config/fbConfig";

const ProtectedRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * This observer runs whenever the user auth state changes.
   * It checks if the user is logged in and if their email is verified.
   * If the user is not logged in, it displays a toast error and redirects the user to the login page.
   * If the user is logged in but their email is not verified, it displays a different toast error and redirects the user to the verify email page.
   */
  auth.onAuthStateChanged((user) => {
    if (!user) {
      // toast.error(
      //   "Bitte loggen Sie sich ein, um Robowriter nutzen zu können.",
      //   {
      //     position: "top-right",
      //     autoClose: 7000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "light",
      //     toastId: "authentication_error",
      //   }
      // );

      navigate(`/sign-in${location.search}`);
    } else if (!user.emailVerified) {
      // toast.error(
      //   "Bitte verifizieren Sie ihre E-Mail-Adresse, um Robowriter nutzen zu können.",
      //   {
      //     position: "top-right",
      //     autoClose: 7000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "light",
      //     toastId: "email_validation_error",
      //   }
      // );

      navigate(`/verify-email${location.search}`);
    }
  });

  return (
    <Outlet />
  );
};
export default ProtectedRoutes;