import React from 'react';

const ImprintCard = () => {
  const currentDate = new Date();
  const cutoffDate = new Date('2024-07-15T00:00:00+02:00'); // Berlin is usually +1 in winter and +2 in summer

  const companyName = currentDate >= cutoffDate ? 'Robo-Technologies GmbH' : 'Endler, Standke, Klintsaris GbR';

  return (
    <div className="mt-24 bg-gradient-to-tl md:bg-gradient-to-l to-purple-500 via-blue-400 from-blue-500 rounded-2xl py-8 px-4 md:px-8 text-white">
      <div className="space-y-4 text-left">
        <h3 className="text-3xl font-semibold mb-4">Impressum</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <h4 className="font-semibold">Angaben gemäß § 5 TMG:</h4>
            <p>{companyName}</p>
            <p>Vertreter: Jakob Endler, Mark Standke, Alexandros-Evangelos Klintsaris</p>
            <p>Kölnstr. 179b</p>
            <p>53111 Bonn</p>
          </div>
          <div>
            <h4 className="font-semibold">Links:</h4>
            <p>
              <a href="https://www.iubenda.com/privacy-policy/91682523" target="_blank" rel="noreferrer">Datenschutzerklärung</a>
            </p>
            <p>
              <a href="https://www.iubenda.com/nutzungsbedingungen/91682523" target="_blank" rel="noreferrer">Nutzungsbedingungen</a>
            </p>
            <p>E-Mail: <a href="mailto:kontakt@robowriter.de">kontakt@robowriter.de</a></p>
          </div>
        </div>
        <p className="mt-4 text-sm">
          {companyName} ist weder verpflichtet, noch bereit, an einem alternativen Schlichtungsverfahren vor einer Schlichtungsstellen gem. Verbraucherstreitbeilegungsgesetz teilzunehmen.
        </p>
      </div>
    </div>
  );
};

export default ImprintCard;