import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { toast } from "react-toastify";
import { Card, CardContent, CardHeader, CardTitle } from "~/components/ui/card";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "~/components/ui/input-otp"
import { Button } from "~/components/ui/button"
import CustomSuccessIcon from "~/components/toast/custom_success_icon";
import CustomErrorIcon from "~/components/toast/custom_error_icon";
import { functions } from "../../config/fbConfig";
import { httpsCallable } from "firebase/functions";
import NavbarAuth from "~/layouts/NavbarAuth";
import Container from "~/components/Container";
import FooterInside from "~/layouts/FooterInside";
import { Loader2 } from "lucide-react";
import { handleUserRedirect } from "~/utils/loginUtils";
import SpinningLoader from "~/components/loader/spinning_loader";

const VerifyEmailPage = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [animationState, setAnimationState] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isResending, setIsResending] = useState(false);

  // Functions
  const check_verification_code = httpsCallable(functions, "check_verification_code");
  const resend_verification_code = httpsCallable(functions, "resend_verification_code");

  const handleResendVerificationEmail = () => {
    setIsResending(true);

    resend_verification_code()
      .then(() => {
        toast.success("Verifizierungs-E-Mail gesendet", {
          position: "top-right",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "lg:w-custom lg:h-custom text-gray-600 text-s font-normal rounded-sm shadow-sm", // or use rounded-none for no rounding
          icon: CustomSuccessIcon()
        });
        setIsResending(false);
      })
      .catch((error) => {
        toast.warning("Fehler beim Senden der Verifizierungs-E-Mail", {
          position: "top-right",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "lg:w-custom lg:h-custom text-gray-600 text-s font-normal rounded-sm shadow-sm", // or use rounded-none for no rounding
          icon: CustomErrorIcon()
        });
        console.error(error);
        setIsResending(false);
      });
  };

  const handleVerify = async () => {
    setIsVerifying(true);

    const result = await check_verification_code({ code: value });

    console.log(result);

    if (typeof result.data === 'object' && result.data !== null && 'success' in result.data) {
      setAnimationState(result.data.success ? "correct" : "incorrect");

      if (result.data.success) {

        console.log(getAuth().currentUser?.emailVerified);
        setIsLoading(true);

        // Redirect to the correct page
        handleUserRedirect(getAuth(), navigate, true);
      } else if (result.data.success === false && 'message' in result.data && result.data.message === "Verification code has expired") {
        toast.warning("Der Verifizierungscode ist abgelaufen, es wurde eine neue E-Mail mit einem neuen Code gesendet.", {
          position: "top-right",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          icon: CustomErrorIcon()
        });
        handleResendVerificationEmail();
      }

    } else {
      console.error("Unexpected result structure:", result.data);
      setAnimationState("incorrect");
    }

    setTimeout(() => setAnimationState(""), 1000);

    setIsVerifying(false);
  };

  // UseEffect to check if the user is verified
  // TODO: Check which navigate fires first
  useEffect(() => {
    if (getAuth().currentUser?.emailVerified) {
      navigate("/templates");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading === true)
    return (
      <SpinningLoader />
    );

  return (
    <div className="min-h-screen flex flex-col">
      <NavbarAuth />
      <Container>
        <section className="flex-1 py-8 flex flex-col items-center justify-center relative">
          <Card className="w-full max-w-[500px] mt-6 sm:mt-20 ">
            <CardHeader>
              <CardTitle className="text-2xl font-bold text-center">Verifiziere Deine E-Mail-Adresse</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-center mb-6">
                Ein 4-stelliger Code wurde an {getAuth().currentUser?.email} gesendet. Bitte gib den Code unten ein, um Deine E-Mail-Adresse zu verifizieren.
              </p>
              <div className="flex justify-center space-x-2 mb-6 relative">
                <InputOTP
                  maxLength={4}
                  value={value}
                  onChange={(value) => setValue(value)}
                  onComplete={handleVerify}
                  disabled={isVerifying}
                  className="sm:text-lg md:text-xl"
                >
                  <InputOTPGroup className={`transition-colors duration-300 ${animationState === "correct" ? "bg-green-100" :
                    animationState === "incorrect" ? "bg-red-100" : ""
                    }`}>
                    <InputOTPSlot className="w-14 h-14" index={0} />
                    <InputOTPSlot className="w-14 h-14" index={1} />
                    <InputOTPSlot className="w-14 h-14" index={2} />
                    <InputOTPSlot className="w-14 h-14" index={3} />
                  </InputOTPGroup>
                </InputOTP>
                {isVerifying && (
                  <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
                    <Loader2 className="h-6 w-6 animate-spin text-primary-500" />
                  </div>
                )}
              </div>
              <p className="text-sm text-center text-gray-500 mb-6">
                Bitte halte diese Seite beim Überprüfen Deiner E-Mail-Ad­res­se geöffnet.
              </p>

              <Button
                onClick={handleResendVerificationEmail}
                variant="outline"
                className="w-full"
                disabled={isResending}
              >
                {isResending ? (
                  <Loader2 className="h-4 w-4 animate-spin mr-2" />
                ) : null}
                Code erneut senden
              </Button>

              <div className="mt-4 text-center">
                <span className="text-sm text-center text-gray-500 mb-6">
                  E-Mail-Ad­res­se falsch eingegeben?{" "}
                  <button
                    onClick={() => {
                      getAuth().signOut().then(() => {
                        navigate("/sign-up");
                      });
                    }}
                    className="text-secondary hover:underline focus:outline-none"
                  >
                    Ausloggen
                  </button>
                </span>
              </div>
            </CardContent>
          </Card>
        </section>
      </Container>
      <FooterInside />
    </div>
  );
}

export default VerifyEmailPage;