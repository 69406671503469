import { Link } from "react-router-dom";
import LayoutInSide from "../../layouts/LayoutInSide";
import { useLocation } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import firebase from "../../config/fbConfig";
import { toast } from "react-toastify";
import SpinningLoader from "../../components/loader/spinning_loader";
import Loader from "../../components/loader/loader";
import templates from "../../data/templates.json";
import { httpsCallable } from "firebase/functions";
import {
  firestore,
  functions,
  doc,
  getDoc,
  onSnapshot,
  auth,
  gemini_model
} from "../../config/fbConfig";
import { UserData, getUserTier, getAllUserData, setStopPrompt } from "../../utils/userUtils";
import { usePostHog } from 'posthog-js/react';
import { SingleLineInput, TextArea } from '../../components/inputs/TextInput';
import Cookies from 'js-cookie'

// Modular Fireabse SDK imports
import { collection, setDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from "react-router-dom";
import { DeleteIcon } from "../../assets/Icons";
import { useShareableData } from "../../providers/ShareableDataProvider";
import { UserGuideStepEnum } from "../../consts";

// Upgrade Modal
import UpgradeModal from "../../layouts/Modal/UpgradeModal";

// Firebase Function Reference
const resettemplate = httpsCallable(functions, "resettemplate_py");
const deductCreations = httpsCallable(functions, "deductCreations");
const sendSlackMessage = httpsCallable(functions, "send_slack_alert");

const TemplateDetailPage = () => {

  // Constants
  const max_input_length = 2000;
  const languages = [
    { value: 'German', label: 'Deutsch' },
    { value: 'English', label: 'English' },
    { value: 'French', label: 'Französisch' },
    { value: 'Spanish', label: 'Spanisch' },
    { value: 'Italian', label: 'Italienisch' },
    { value: 'Portuguese', label: 'Portugisisch' },
    { value: 'Dutch', label: 'Niederländisch' },
    { value: 'Swedish', label: 'Schwedisch' },
    { value: 'Danish', label: 'Dänisch' },
    { value: 'Norwegian', label: 'Norwegisch' },
    { value: 'Finnish', label: 'Finnisch' },
    { value: 'Polish', label: 'Polnisch' },
    { value: 'Russian', label: 'Russisch' },
    { value: 'Turkish', label: 'Türkisch' },
  ];

  // State Variables
  const [generatedText, setGeneratedText] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [loading, setLoading] = useState(false);
  const [startedGeneration, setStartedGeneration] = useState(false);
  const [cancel, setCancel] = useState(false);
  const cancelRef = useRef(false);
  const [stopping] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(true);
  const [templateEditable, setTemplateEditable] = useState(false);
  const [allowEdit, setAllowEdit] = useState(false);
  const [showIndicator, setShowIndicator] = useState(false);
  const [textLength, setTextLength] = useState(0);
  const [error, setError] = useState("");
  const [userData, setUserData] = useState<UserData>({ "userData": "test", "productData": "test" });
  const [language, setLanguage] = useState(null || String)
  const [formValues, setFormValues] = useState<Record<number, string>>({});
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  // Providers
  const posthog = usePostHog();
  const [personas, setPersonas] = useState([{ "id": "", "name": "", "personatext": "" }])
  const templateDetail = useLocation();
  const { showOnBoarding, completeOnBoardingStep} = useShareableData();

  // References
  const selectedTemplate = templateDetail.state;
  const inputRefs = useRef<(HTMLInputElement | HTMLTextAreaElement)[]>([]);
  const outputLanguageRef = useRef<HTMLSelectElement>(null);
  const answerLengthRef = useRef<HTMLSelectElement>(null);
  const personaRef = useRef<HTMLSelectElement>(null);

  // Unique generation id, used to track the generation
  // its a combination of the user id and the current timestamp
  // its assigned a value every time a generation is started
  let generation_id = "";

  // Functions & Event Handlers

  const getUserTemplates = async () => {
    const data: any[] = [];

    const writeDataDocRef = collection(firestore, "users", auth.currentUser!.uid, "personas");

    onSnapshot(writeDataDocRef, (snapshot: any) => {
      const personasData = snapshot.docs.map((doc: any) => ({ id: doc.id, ...doc.data() }));
      personasData.sort((a: any, b: any) => (a.created_at > b.created_at) ? 1 : -1);
      setPersonas([{ "id": "", "name": "Standard", "personatext": "" }, ...personasData])
    });

    await firebase
      .firestore()
      .collection("users")
      .doc(auth.currentUser!.uid)
      .collection("templates")
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          data.push(doc.data().template_data);
        });
      })
      .catch(err => {
        console.log(err.message);
      });

    return data;
  };

  const loadUserTemplates = async () => {
    setLoadingTemplates(true);
    const userTemplates = await getUserTemplates();
    const retrievedUserData = await getAllUserData(auth.currentUser!.uid);
    setUserData(retrievedUserData);
    // Prepending the user templates to the templates from JSON
    const templates_out = [...userTemplates, ...templates];
    setLoadingTemplates(false);

    if (userTemplates.some(template => template.name === selectedTemplate.title)) {
      setTemplateEditable(true);
    }
    const userTier = await getUserTier(auth.currentUser!.uid);
    if (userTier.editdynamictemplates) {
      setAllowEdit(true)
    }
    return templates_out
  }


  const loadOutputField = async () => {
    if (auth.currentUser) {
      const userDocRef = doc(collection(firestore, "users"), auth.currentUser!.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        // Set up real-time listener
        onSnapshot(userDocRef, (userDocSnap) => {
          if (userDocSnap.exists()) {
            setLoading(false);
            const userDocData = userDocSnap.data();
            if (Cookies.get('useFallback') !== 'true') {
              textareaRef.current!.value = userDocData.currentresponse;
              setGeneratedText(userDocData.currentresponse);
            }
            // Update userData with creations and bonus_creations
            setUserData(prevUserData => ({
              ...prevUserData,
              userData: {
                ...prevUserData.userData,
                creations: userDocData.creations,
                bonus_creations: userDocData.bonus_creations
              }
            }));
          } else {
            console.error("User document not found");
          }
        });
      } else {
        console.error("User document not found");
      }
    } else {
      console.error("User not authenticated");
    }
  };

  const renderPlaceholders = () => {
    if (loadingTemplates) {
      return <SpinningLoader />
    }
    const placeholders = selectedTemplate.placeholders;

    return placeholders.map((placeholder: any, index: any) => {
      const { label, type } = placeholder;
      return (
        <div className="mb-4" key={index}>
          <div className="flex flex-col mb-3.5">
            <div className="flex justify-between">
              <label htmlFor="theme" className=" text-[13px] font-medium flex items-center gap-2 mb-3">{label}
                {/* i-Icon, hidden for now until we merge the tooltips */}
                <span hidden><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_5_4867)">
                    <path d="M6.5 0C7.09668 0 7.6722 0.0761719 8.22656 0.228516C8.78092 0.380859 9.2972 0.600911 9.77539 0.888672C10.2536 1.17643 10.6916 1.51497 11.0894 1.9043C11.4871 2.29362 11.8278 2.73161 12.1113 3.21826C12.3949 3.70492 12.6128 4.22331 12.7651 4.77344C12.9175 5.32357 12.9958 5.89909 13 6.5C13 7.09668 12.9238 7.6722 12.7715 8.22656C12.6191 8.78092 12.3991 9.2972 12.1113 9.77539C11.8236 10.2536 11.485 10.6916 11.0957 11.0894C10.7064 11.4871 10.2684 11.8278 9.78174 12.1113C9.29508 12.3949 8.77669 12.6128 8.22656 12.7651C7.67643 12.9175 7.10091 12.9958 6.5 13C5.90332 13 5.3278 12.9238 4.77344 12.7715C4.21908 12.6191 3.7028 12.3991 3.22461 12.1113C2.74642 11.8236 2.30843 11.485 1.91064 11.0957C1.51286 10.7064 1.1722 10.2684 0.888672 9.78174C0.605143 9.29508 0.387207 8.77669 0.234863 8.22656C0.0825195 7.67643 0.00423177 7.10091 0 6.5C0 5.90332 0.0761719 5.3278 0.228516 4.77344C0.380859 4.21908 0.600911 3.7028 0.888672 3.22461C1.17643 2.74642 1.51497 2.30843 1.9043 1.91064C2.29362 1.51286 2.73161 1.1722 3.21826 0.888672C3.70492 0.605143 4.22331 0.387207 4.77344 0.234863C5.32357 0.0825195 5.89909 0.00423177 6.5 0ZM7.3125 9.75V8.125H5.6875V9.75H7.3125ZM7.3125 7.3125V3.25H5.6875V7.3125H7.3125Z" fill="#CCCCCC" />
                  </g>
                  <defs>
                    <clipPath id="clip0_5_4867">
                      <rect width="13" height="13" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                </span>
              </label>
              {type === "textarea" && (
                <div>
                  <p className="text-[13px] text-gray-1000 font-medium font-poppins"><span>{textLength}</span>/{max_input_length}</p>
                </div>
              )}
            </div>
            <div>
              {type === "textarea" && (
                <>
                  <div className='relative'>
                    <TextArea className="custom-scrollbar"
                      maxLength={Number.MAX_SAFE_INTEGER}
                      style={{ height: '200px', resize: 'vertical', paddingRight: '1.5rem' }}
                      label="textarea"
                      handleInputRef={(ref) => { handleInputRef(ref, index) }}
                      onChangeHandler={(e) => { onChangeHandler(e, index) }}
                    />
                    {formValues[index] && (
                      <button
                      className="absolute top-[0.625rem] right-[0.625rem]"
                        style={{
                          visibility: inputRefs.current[index]?.value !== '' ? 'visible' : 'hidden'
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          onClearHandler(index);
                        }}
                      >
                        <DeleteIcon />
                      </button>
                    )}
                  </div>
                </>
              )}

              {type === "input" && (
                <>
                <div className='relative'>
                  <SingleLineInput
                    maxLength={Number.MAX_SAFE_INTEGER}
                    label="text"
                    style={{ paddingRight: '1.5rem' }}
                    handleInputRef={(ref) => { handleInputRef(ref, index) }}
                    onChangeHandler={(e) => { onChangeHandler(e, index) }}
                  />
                  {formValues[index] && (
                  <button onClick={(e) => {
                    e.preventDefault()
                    onClearHandler(index)
                  }}
                  className="absolute top-[0.88rem] right-[0.625rem]"
                  >
                    <DeleteIcon />
                  </button>
                  )}
                </div>
                </>
              )}
            </div>
            <p className="text-red-500 mt-5">{error}</p>
          </div>
        </div>
      );
    });
  };

  function getOpenAIResponse(prompt: string, systemPrompt: string) {
    // Return early if stopping is in progress
    if (stopping) {
      return Promise.resolve({
        success: false,
        message: "Stopping in progress",
      });
    }

    // Posthog Event for template_detail_page:start_generation
    // Includes a unqiue generation id, which is used to track the generation
    // also the template, system_prompt and prompt are included
    generation_id = `${posthog.get_distinct_id()}_${Date.now()}`;
    posthog.capture('template_detail_page:start_generation', {
      generation_id: generation_id,
      template: selectedTemplate.title,
      prompt: prompt,
      system_prompt: systemPrompt,
    });

    // Add Clientside Timeout, similar to the function timeout
    // ref: https://stackoverflow.com/questions/74895742/firebase-function-firebase-error-deadline-exceeded-when-it-hasnt-timed-out-yet
    const getopenai = httpsCallable(functions, "getopenai_py", { timeout: 300000 });

    // Create a timeout promise that checks if the textarea is empty after 3 seconds
    // Throws an error if no text is generated after 3 seconds, and moves to the fallback function
    const timeoutPromise = new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        if (textareaRef.current?.value === "") {
          reject(new Error("Timeout: No text generated after 3 seconds"));
        } else {
          // Don't resolve, otherwise the OpenAI result will be returned as undefined.
        }
      }, 3000); // 5 seconds (5,000 ms) timeout
    });

    setStartedGeneration(true);
    return Promise.race([getopenai({ prompt: prompt, systemPrompt: systemPrompt, template: selectedTemplate.title, generation_id: generation_id }), timeoutPromise])
      .then((result: any) => {
        const data = result.data as {
          success: boolean;
          message: string;
          uid?: string;
        };
        // Catch Negative Returns
        if (!data.success) {
          if (data.message === "Words exhausted") {
            // If no words are left, show a toast
            setShowUpgradeModal(true);
          }
          else {
            // Set Session Cookie to continue to use Gemini
            // Expires in 15 minutes (1/96 of a day)
            Cookies.set('useFallback', 'true', { expires: 1/96 });

            // If not on localhost or test environment, send a slack message
            if (window.location.hostname !== 'localhost' && window.location.hostname !== 'tst-robowriter.web.app') {
              sendSlackMessage({
                message: `Error in OpenAI Generation, falling back to Gemini. Error: ${data.message}`,
                generation_id: generation_id,
                url: window.location.href
              });
            }

            // On Error, Fallback to Client-side Gemini Generation
            getGeminiResponse(prompt, systemPrompt);
          }
        }
        return data;
      })
      .catch((error) => {
        console.log(error);

        setLoading(false);

        // Set Session Cookie to continue to use Gemini
        // Expires in 15 minutes (1/96 of a day)
        Cookies.set('useFallback', 'true', { expires: 1/96 });

        // If not on localhost or test environment, send a slack message
        if (window.location.hostname !== 'localhost' && window.location.hostname !== 'tst-robowriter.web.app') {
          sendSlackMessage({
            message: `Error in OpenAI Generation, falling back to Gemini. Error: ${error}`,
            generation_id: generation_id,
            url: window.location.href
          });
        }

        // On Error, Fallback to Client-side Gemini Generation
        getGeminiResponse(prompt, systemPrompt);
      });
  }

  /**
   * This asynchronous function is used to get a response from the Gemini model.
   * It first resets the value of a textarea, then logs an event with Posthog.
   * After that, it calls the `generateContentStream` method from the `gemini_model` 
   * with a combination of `systemPrompt` and `prompt` as the input.
   * 
   * Once the content generation is done, it sets the loading state to false.
   * It then iterates over the result stream, appending each chunk of generated text 
   * to the current value of the textarea.
   * 
   * @param {string} prompt - The prompt to be used for generating content.
   * @param {string} systemPrompt - The system prompt to be used for generating content.
   * @async
   */
  async function getGeminiResponse(prompt: string, systemPrompt: string, due_to_timeout = false) {
    // Reset the generated text
    textareaRef.current!.value = "";

    // Pull new user data
    setUserData(await getAllUserData(auth.currentUser!.uid));

    // check if the user has any words left in their plan
    // if not, show a toast and return
    if (!userData.userData!.unlimited && Number(userData.userData!.creations) <= 0 && Number(userData.userData!.bonus_creations) <= 0) {
      setShowUpgradeModal(true);
      return;
    }

    // Posthog Event for template_detail_page:fallback_gemini
    posthog.capture('template_detail_page:fallback_gemini', {
      generation_id: generation_id,
      template: selectedTemplate.title,
      prompt: prompt,
      system_prompt: systemPrompt,
    });

    try {
      // To stream generated text output, call generateContentStream with the text input
      const result = await gemini_model.generateContentStream(`${systemPrompt}\n\n${prompt}`);

      setLoading(false);
      cancelRef.current = false;
      setCancel(false);   // Update state to trigger re-render

      for await (const chunk of result.stream) {
        if (cancelRef.current){
          cancelRef.current = true
          setCancel(true);   // Update state to trigger re-render
          break
        }
        const chunkText = chunk.text();

        // Get current text in the textarea
        const currentText = textareaRef.current?.value;

        // Append the new text to the textarea
        if (Cookies.get('useFallback') === 'true') {
          textareaRef.current!.value = currentText + chunkText;
          setGeneratedText(currentText + chunkText)
        }
      }

      // Pull new user data
      setUserData(await getAllUserData(auth.currentUser!.uid));

      // Deduct words only when its not a timeout issue, otherwise the words will be deducted twice
      if (!due_to_timeout) {
        // Call the "deductWords" function to deduct the total words from the user's plan
        cancelRef.current = true
        setCancel(true);   // Update state to trigger re-render
        await deductCreations({ creations: 1 });
      }
    }
    catch (error) {
      // Gemini sometimes throws safety errors on reponses it deems unsafe
      cancelRef.current = true
      setCancel(true);   // Update state to trigger re-render
      if (error instanceof Error && error.message.includes("blocked due to SAFETY")) {
        // Handle Gemini Safety Error
        toast.error(
          "Unsere KI hat die Antwort aufgrund von Sicherheitsbedenken blockiert. Bitte versuche es mit einer anderen Anfrage erneut.",
          {
            position: "top-right",
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
      else if (error instanceof Error && error.message.includes("Quota exceeded")) {
        // Handle Gemini Safety Error
        toast.error(
          "Unsere KI hat die Antwort aufgrund von zu vielen Anfragen blockiert. Bitte versuche es ein wenig später erneut.",
          {
            position: "top-right",
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else {
        // Handle other Gemini Errors
        toast.error(
          "Ein unbekannter Fehler ist aufgetreten, wir arbeiten daran ihn zu beheben. Bitte versuche es später erneut.",
          {
            position: "top-right",
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    }
  }

  const handleInputRef = (ref: any, index: number) => {
    inputRefs.current[index] = ref;
  };

  const handleUpgradeToast = () => {
    toast.warning(
      "Vorlagen können ausschließlich mit einem Starter oder Creator Plan bearbeitet werden. Bitte upgraden Sie.",
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
  };

  const handleLanguageChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const language = event.target.value;
    setLanguage(language)
    if (auth.currentUser) {
      const writeDataDocRef = doc(collection(firestore, "users", auth.currentUser!.uid, "writedata"), 'appdata');
      const docSnapshot = await getDoc(writeDataDocRef);
      if (docSnapshot.exists()) {
        await updateDoc(writeDataDocRef, {language});
      } else {
        await setDoc(writeDataDocRef, {language});
      }
    }
  }

  const copyOutputText = () => {
    navigator.clipboard.writeText(generatedText);
    setShowIndicator(true);

    // Track copy event with posthog
    if (!sessionStorage.getItem('copyEventFired') || sessionStorage.getItem('copyEventFired') === 'false') {
      posthog.capture('template_detail_page:copy_output_text', {
        generation_id: generation_id,
        template: selectedTemplate.title
      });
      sessionStorage.setItem('copyEventFired', 'true');
    }

    setTimeout(() => setShowIndicator(false), 2000);
  };

  // event handler for users who ctrl-c to copy the output text
  document.addEventListener('keydown', function (e) {
    if (e.ctrlKey && e.key === 'c') {
      const textarea = document.getElementById('text');
      if (textarea && textarea === document.activeElement) {
        // Track copy event with posthog
        if (!sessionStorage.getItem('copyEventFired') || sessionStorage.getItem('copyEventFired') === 'false') {
          posthog.capture('template_detail_page:copy_output_text', {
            generation_id: generation_id,
            template: selectedTemplate.title
          });
          sessionStorage.setItem('copyEventFired', 'true');
        }
      }
    }
  });

  document.addEventListener('contextmenu', function (e) {
    const textarea = document.getElementById('text');
    if (textarea && textarea === e.target) {
      // Track copy event with posthog
      if (!sessionStorage.getItem('copyEventFired') || sessionStorage.getItem('copyEventFired') === 'false') {
        posthog.capture('template_detail_page:copy_output_text', {
          generation_id: generation_id,
          template: selectedTemplate.title
        });
        sessionStorage.setItem('copyEventFired', 'true');
      }
    }
  });

  const onChangeHandler = (e: any, index: number) => {
    setFormValues(prev => ({
      ...prev,
      [index]: e.target.value
    }))
    let totalValue = "";
    inputRefs.current.forEach((input: any) => {
      if (input && input.value) {
        totalValue += input.value + " ";
      }
    });

    const totalWordsCount = totalValue
      .split(/\s+/)
      .filter((word) => word !== "").length;

    setTextLength(totalWordsCount);

    if (totalWordsCount > max_input_length) {
      setError(
        `Du benutzt zu viele Wörter in der Eingabe. Das Maximum wird um: ${totalWordsCount - max_input_length
        } Wörter überschritten.`
      );
      inputRefs.current[index].style.border = '1px solid red'
      posthog.capture('template_detail_page:character_limit_reached', {
          generation_id: generation_id,
          template: selectedTemplate.title,
          word_cnt: totalWordsCount
        });
    } else {
      inputRefs.current[index].style.border = ''
      setError("");
    }
  };

  const onClearHandler = (index: number) => {
    inputRefs.current[index].value = '';
    setFormValues(prev => ({
      ...prev,
      [index]: ''
    }))
  }

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    // Handle Cancel Button
    if (!cancelRef.current) {
      cancelRef.current = true
      setCancel(true);   // Update state to trigger re-render
      if (Cookies.get('useFallback') === 'true') {
        return Promise.resolve({
          success: false,
          message: "Canceling in progress",
        });
      } else {
        await setStopPrompt(auth.currentUser!.uid);
      }
    }
    try {
      //       const tone = toneRef.current?.value;
      const language = outputLanguageRef.current?.value;
      const response_length = answerLengthRef.current?.value ?? "";
      const persona = personaRef.current?.value ?? "";

      const inputValues = inputRefs.current.map((input: any) => input.value);
            
      if (showOnBoarding){
        completeOnBoardingStep(UserGuideStepEnum.AI_TEXT)
      }

      inputValues[inputValues.length] = ""; // tone;


      let prompt = selectedTemplate.prompt;
      selectedTemplate.replaceValues.forEach((values: any, index: number) => {
        prompt = prompt.replace(`{${values}}`, inputValues[index]);
      });
      let systemPrompt = `You may only respond in the following laguage: ${language}`;
      if (response_length.toLowerCase() === "kurz") {
        systemPrompt = `${systemPrompt}. Antworte sehr kurz.`;
      }
      if (response_length.toLowerCase() === "lang") {
        systemPrompt = `${systemPrompt}. Antworte eher lang.`;
      }
      if (persona !== "") {
        systemPrompt = `${systemPrompt}. Schreibe deine Antwort im Stil des folgenden Beispiels/folgenden Stil Beschreibung. Achte bei einem Beispiel auf besondere Ausdrücke und den Satzbau des Beispiels.\n\nStil Beispiel/Beschreibung:\n ${persona}.`;
      }
      if (cancelRef.current) {
        // Set the event fired event to false on new generation
        sessionStorage.setItem('copyEventFired', 'false');

        // Wrap Text Generation in try-catch block,
        // move to local gemini calls when openai in Firebase Functions fails
        try {
          // Check if generation has failed once this session, if yes, fallback to Gemini
          if (Cookies.get('useFallback') === 'true') {
            // Throw Error to Fallback to Gemini Generation
            throw new Error("Fallback Cookie set, using Gemini");
          }
          
          await getOpenAIResponse(prompt, systemPrompt);
        } catch (error) {
          console.error(error);

          // On Error, Fallback to Client-side Gemini Generation
          await getGeminiResponse(prompt, systemPrompt);
        }

      } else {
        await setStopPrompt(auth.currentUser!.uid);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const setLanguagefromFB = async () => {
    const writeDataDocRef = doc(collection(firestore, "users", auth.currentUser!.uid, "writedata"), 'appdata');
    const docSnapshot = await getDoc(writeDataDocRef);
    if (docSnapshot.exists()) {
      const currentState = docSnapshot.data();
      setLanguage(currentState.language)
    }
  }
  const navigate = useNavigate();

  const handleSelectChange = (event:React.ChangeEvent<HTMLSelectElement> ) => {
    if (event.target.value === 'create-tone') {
      navigate('/tonfall');
    }
  };

  /* UseEffect Hooks */

  useEffect(() => {
    // scroll to the bottom
    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  }, [generatedText]);

    useEffect(() => {
    setCancel(cancelRef.current);   // Update state to trigger re-render
  });

  // Posthog Event for template_detail_page:template_loaded
  useEffect(() => {
    posthog.capture('template_detail_page:template_loaded', {
      template: selectedTemplate.title
    });
  }, [selectedTemplate.title, posthog]); // Effect runs whenever auth.uid or selectedTemplate.title changes

  // UseEffect to initialize the detail page
  useEffect(() => {
    cancelRef.current = true
    setCancel(true);   // Update state to trigger re-render
    loadUserTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    resettemplate().then(() => {
      loadOutputField();
    });

    // Setup onSnapshot listener for cancel signal
    const docRef = firebase.firestore().collection("users").doc(auth.currentUser!.uid);
    docRef.onSnapshot((doc: any) => {
      const data = doc.data();
      if (data && data.currentlywriting !== undefined && (Cookies.get('useFallback') !== 'true')) {
        cancelRef.current = !data.currentlywriting;
        setCancel(!data.currentlywriting);   // Update state to trigger re-render
      }
    });

    setLanguagefromFB()
  }, []);


  if (loadingTemplates) {
    return <SpinningLoader />
  } else {
    return (
      <>
        {loading && <Loader />}
        <LayoutInSide
          userData={userData}
        >
          <form noValidate onSubmit={onSubmitHandler}>
            {/* Two Column Grid Layout */}
            <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-8 p-2 w-full">
              {/* First Column, should take up one third of space */}
              <div className="col-span-1 w-full md:h-[80vh] overflow-auto custom-scrollbar md:pr-8">
                <style>{`
                .custom-scrollbar::-webkit-scrollbar {
                  width: 10px;
                }
      
                .custom-scrollbar::-webkit-scrollbar-track {
                  background: transparent; 
                }
      
                .custom-scrollbar::-webkit-scrollbar-thumb {
                  background: #888; 
                  border-radius: 10px;
                }
      
                .custom-scrollbar::-webkit-scrollbar-thumb:hover {
                  background: #555; 
                }
      
                /* Buttons */
                .custom-scrollbar::-webkit-scrollbar-button {
                  display: none;
                }
              `}</style>
                {/* Header & Subtitle */}
                <div className="mb-9">
                  <h1 className=" md:text-[32px] te xt-xl font-Outfit font-semibold">{selectedTemplate.title}</h1>
                  <p className="md:text-[15px] text-[12px] text-gray-4000">{selectedTemplate.description}</p>
                </div>
                {/* Input Fields */}
                <div className="w-full">
                  {renderPlaceholders()}
                  <div className="mb-5">
                    <ul className="grid grid-cols-1 lg:grid-cols-2 gap-4 xl:gap-6">
                      <li className="xl:col-span-1 col-span-2 mb-4 xl:mb-0">
                        <div
                          className="w-full flex flex-col justify-between">
                          {/* Language Selector */}
                          <label className="text-[13px] font-medium flex items-center gap-2 mb-2">
                            Antwort Sprache
                          </label>
                          <select
                            className="custom-select border-gray-300 rounded w-full"
                            ref={outputLanguageRef}
                            value={language || ''}
                            onChange={handleLanguageChange}
                          >
                            {languages.map((language) => (
                              <option key={language.value} value={language.value}>
                                {language.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </li>
                      <li className="xl:col-span-1 col-span-2">
                        {/* Length Selector */}
                        <div
                          className="w-full flex flex-col justify-between">
                          <label className="text-[13px] font-medium flex items-center gap-2 mb-2">
                            Antwort Länge
                          </label>
                          <select
                            className="custom-select border-gray-300 rounded w-full"
                            ref={answerLengthRef}
                          >
                            <option value="Normal">Normal</option>
                            <option value="Kurz">Kurz</option>
                            <option value="Lang">Lang</option>
                          </select>
                        </div>
                      </li>

                      <li className="lg:col-span-2">
                        {/* Persona Selector */}
                        <div
                          className="w-full col-span-2 flex flex-col justify-between">
                          <label className="text-[13px] font-medium flex items-center gap-2 mb-2">
                            Tonfall
                          </label>
                          <select
                            className="col-span-2 custom-select border-gray-300 rounded w-full"
                            ref={personaRef}
                            onChange={handleSelectChange}
                          >
                            {personas.map((singlePersona) => (
                              <option key={singlePersona.personatext} value={singlePersona.personatext}>
                                {singlePersona.name}
                              </option>
                            ))}
                            <option value="create-tone"> + Neuen Tonfall erstellen</option>
                          </select>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {/* Start and Stop Buttons */}
                  <div className="flex w-full justify-center mt-8 pb-12">
                    <button
                      type="submit"
                      className={`py-2 h-12 px-12 w-full rounded ${cancel
                        ? "bg-secondary text-white text-lg hover:bg-secondary-600"
                        : "bg-white border-[1px] border-secondary hover:bg-secondary-600 hover:text-white text-secondary"
                        }`}
                    >
                      {cancel ? "Start" : "Stop"}
                    </button>
                  </div>
                </div>
              </div>
              {/* Second Column */}
              <div className="col-span-2 w-full">
                <div
                  className="flex flex-col w-full md:flex-row md:justify-between items-start md:items-center mb-9 mt-9 lg:mt-0">
                  {/* Output Field Title */}
                  <div>
                    <h1 className="md:text-[32px] text-xl font-Outfit font-semibold mb-2">Output</h1>
                    <p className="md:text-[15px] text-[12px] text-gray-4000">Hier ist dein generierter Content.</p>
                  </div>

                  {/* Edit Template Buttons */}
                  {templateEditable && (
                    <div className="mt-8 flex justify-center w-full md:w-auto">
                      {allowEdit ? (
                        <Link
                          to={window.location.href.replace("templates", "edit")}
                          state={selectedTemplate}
                          className="py-2 h-12 px-6 rounded border border-gray-4000 bg-transparent text-gray-4000"
                        >
                          Vorlage Bearbeiten
                        </Link>
                      ) : (
                        <button
                          onClick={(event) => {
                            event.preventDefault();
                            handleUpgradeToast();
                          }}
                          className="py-2 h-12 px-6 rounded border border-gray-4000 bg-transparent text-gray-4000"
                        >
                          <div className="flex items-center">
                            <span className="mr-1">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="h-5 w-5">
                                <path fillRule="evenodd" d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z" clipRule="evenodd"></path>
                              </svg>
                            </span>
                            Vorlage Bearbeiten
                          </div>
                        </button>
                      )}
                    </div>
                  )}
                </div>
                {/* Output Field Box */}
                {/* Defaults to 70% height and 80% width on medium screens and up, and to auto on mobile screens */}
                <div className="w-full border-2 border-primary relative p-7 rounded h-auto md:h-[70%] bg-white">
                  <div className=" w-[calc(100%_+_4px)] bg-primary h-11 absolute -top-[2px] rounded-t-[4px] -left-[2px] px-6 py-2">
                    <div className="h-full flex items-center justify-between w-full">
                      <img className="h-auto w-24" src="/assets/images/logo.svg" alt="logo-text" />
                      <div className="flex gap-5">
                        <div
                          className={`p-2 bg-green-200 text-green-700 border border-green-300 rounded shadow-lg 
                                  transition-all duration-1000 ease-in-out transform w-100px
                                  ${showIndicator ? 'translate-x-4 opacity-100' : 'hidden'}`}>
                          Erfolgreich kopiert!
                        </div>
                        <button onClick={(event) => {
                          event.preventDefault();
                          copyOutputText();
                        }}>
                          <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.016 0H7.5114C5.9238 0 4.6656 0 3.6819 0.1332C2.6685 0.27 1.8486 0.558 1.2024 1.2069C0.5553 1.8558 0.2682 2.6793 0.1323 3.6963C6.70552e-08 4.6845 0 5.9472 0 7.5411V12.7953C0 14.1525 0.828 15.3153 2.0043 15.8031C1.944 14.9841 1.944 13.8366 1.944 12.8808V8.3718C1.944 7.2189 1.944 6.2244 2.0502 5.4288C2.1645 4.5756 2.4219 3.7584 3.0825 3.0951C3.7431 2.4318 4.5576 2.1735 5.4072 2.0583C6.1992 1.9521 7.1892 1.9521 8.3385 1.9521H11.1015C12.2499 1.9521 13.2381 1.9521 14.031 2.0583C13.7934 1.45204 13.3787 0.931405 12.8409 0.564261C12.3031 0.197118 11.6672 0.000488517 11.016 0Z" fill="white" />
                            <path d="M3.24023 8.45731C3.24023 6.00391 3.24023 4.77721 3.99983 4.01491C4.75853 3.25261 5.97983 3.25261 8.42423 3.25261H11.0162C13.4597 3.25261 14.6819 3.25261 15.4415 4.01491C16.2002 4.77721 16.2002 6.00391 16.2002 8.45731V12.7953C16.2002 15.2487 16.2002 16.4754 15.4415 17.2377C14.6819 18 13.4597 18 11.0162 18H8.42423C5.98073 18 4.75853 18 3.99983 17.2377C3.24023 16.4754 3.24023 15.2487 3.24023 12.7953V8.45731Z" fill="white" />
                          </svg>
                        </button>
                        <button onClick={onSubmitHandler}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 7.12336C14.786 5.58067 14.0717 4.15126 12.9671 3.05532C11.8624 1.95938 10.4288 1.25771 8.88696 1.05839C7.34514 0.859076 5.78067 1.17317 4.43455 1.95229C3.08843 2.73142 2.03534 3.93235 1.4375 5.37009M1 1.86354V5.37009H4.5M1 8.87664C1.21399 10.4193 1.92831 11.8487 3.03294 12.9447C4.13757 14.0406 5.57121 14.7423 7.11304 14.9416C8.65486 15.1409 10.2193 14.8268 11.5654 14.0477C12.9116 13.2686 13.9647 12.0677 14.5625 10.6299M15 14.1365V10.6299H11.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="h-full w-full pt-9">
                    <textarea
                      ref={textareaRef}
                      defaultValue={startedGeneration ? generatedText : ""}
                      rows={10}
                      readOnly
                      name="text"
                      id="text"
                      className="h-full w-full bg-transparent border-0 focus:ring-0 max-h-full overflow-auto"
                    />
                  </div>

                </div>
              </div>
            </div>
          </form>
          <UpgradeModal isOpen={showUpgradeModal} onRequestClose={() => setShowUpgradeModal(false)} />
        </LayoutInSide>
      </>
    );
  };
};

export default TemplateDetailPage;
