import { ReactNode, createContext, useContext, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { collection, setDoc, doc, getDoc } from 'firebase/firestore';
import { firestore, auth, updateDoc } from '../config/fbConfig';
import { UserGuideStepEnum, completeOnBoardingStepMessagesMap } from '../consts';
import { setUserOnboardingData } from '../utils/userUtils';
import { usePostHog } from 'posthog-js/react';
import { toast } from "react-toastify";

interface ModalProviderProps {
    children: ReactNode;
}

export interface OnboardingData {
    [UserGuideStepEnum.CREATE_ACCOUNT]: { done: boolean };
    [UserGuideStepEnum.AI_TEXT]: { done: boolean };
    [UserGuideStepEnum.CHAT]: { done: boolean };
    [UserGuideStepEnum.TONE]: { done: boolean };
    [UserGuideStepEnum.TEMPLATE]: { done: boolean };
    [UserGuideStepEnum.NEWSLETTER]: { done: boolean };
}

interface IDataContext {
    onboardingData: OnboardingData | null;
    setOnboardingData: Dispatch<SetStateAction<OnboardingData | null>>;
    showOnBoarding: boolean | null;
    completeOnBoardingStep: (step: UserGuideStepEnum) => Promise<void>;
    closeOnboardingMenu: () => Promise<void>;
}

const DataContext = createContext<IDataContext>({
    onboardingData: null,
    setOnboardingData: () => { },
    showOnBoarding: false,
    completeOnBoardingStep: async () => { },
    closeOnboardingMenu: async () => { }
});

const defaultOnboardingData: OnboardingData = {
    [UserGuideStepEnum.CREATE_ACCOUNT]: { done: true },
    [UserGuideStepEnum.AI_TEXT]: { done: false },
    [UserGuideStepEnum.CHAT]: { done: false },
    [UserGuideStepEnum.TONE]: { done: false },
    [UserGuideStepEnum.TEMPLATE]: { done: false },
    [UserGuideStepEnum.NEWSLETTER]: { done: false },
};

export const ShareableDataProvider: React.FC<ModalProviderProps> = ({ children }) => {
    const posthog = usePostHog();
    const [onboardingData, setOnboardingData] = useState<OnboardingData | null>(null);
    const [isOnboardingClose, setIsOnboardingClose] = useState<boolean | null>(null);
    const showOnBoarding = !isOnboardingClose && (!Object.values(onboardingData || {}).every((step: any) => step.done));

    const getData = async () => {
        const writeDataDocRef = doc(collection(firestore, 'users', auth.currentUser!.uid, 'writedata'), 'appdata');
        const docSnapshot = await getDoc(writeDataDocRef);
        const userDocRef = doc(firestore, 'users', auth.currentUser!.uid);
        const userData = (await getDoc(userDocRef)).data();

        if (userData?.freetrial) {
            if (docSnapshot.exists()) {
                const currentState = docSnapshot.data();
                setOnboardingData(currentState.onboardingData);
                setIsOnboardingClose(currentState.isOnboardingClose);
            } else {
                // Initialization of onboarding data if the document does not exist
                const newsletterSubscribed = userData?.newsletter.subscribed;

                const initialData = {
                    ...defaultOnboardingData,
                    [UserGuideStepEnum.NEWSLETTER]: { done: newsletterSubscribed }
                }

                await setDoc(writeDataDocRef, {
                    onboardingData: initialData,
                    isOnboardingClose: false
                });
                setOnboardingData(initialData);
            }
        }
    };

    const completeOnBoardingStep = async (step: UserGuideStepEnum) => {
        if (onboardingData && !onboardingData[step].done) {
            const updatedData = {
                ...onboardingData,
                [UserGuideStepEnum[step]]: { done: true }
            };

            setOnboardingData(updatedData)
            await setUserOnboardingData(UserGuideStepEnum[step], true, posthog);
            if (completeOnBoardingStepMessagesMap[step] != "") {
                toast.success(completeOnBoardingStepMessagesMap[step], {
                    position: "top-right",
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }

    const closeOnboardingMenu = async () => {
        const writeDataDocRef = doc(collection(firestore, 'users', auth.currentUser!.uid, 'writedata'), 'appdata');
        setIsOnboardingClose(true);
        await updateDoc(writeDataDocRef, { isOnboardingClose: true });
    };

    useEffect(() => {
        if (auth?.currentUser?.uid) {
            getData();
        }
    }, [auth?.currentUser?.uid]);

    return (
        <DataContext.Provider value={{ onboardingData, setOnboardingData, showOnBoarding, completeOnBoardingStep, closeOnboardingMenu}}>
            {children}
        </DataContext.Provider>
    );
};

export const useShareableData = () => useContext(DataContext);