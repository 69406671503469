import { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import Container from "../Container";
import PlanCard from "../cards/PlanCard";
import firebase from "../../config/fbConfig";
import SpinningLoader from "../loader/spinning_loader";

const PlansSection = ({ bgImage, CTA, createUserAndCheckout1, createUserAndCheckout2, createUserAndCheckout3, discount_code }) => {
  
  // State Variables
  const [enabled, setEnabled] = useState(true);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cardOrder, setCardOrder] = useState((CTA === "Jetzt anmelden") ? ["Creator", "Starter", "Kostenlos"] : ["Starter", "Creator"]);
  const [discountInfo, setDiscountInfo] = useState(null);

  // Constants
  const prices = {
    "Kostenlos": {
      "text": [
        `Alle Funktionen des KI-Textgenerators`,
        "Mehr als 50 KI-Vorlagen für alle gängigen Schreibaufgaben",
        "3 Personalisierte Vorlagen",
        "Erstelle bis zu 3 Texte pro Tag"
      ],
      "mostPopular": false
    },
    "Starter": {
      "text": [
        "Bestes KI-Modell",
        `Alle Funktionen des KI-Textgenerators`,
        "Mehr als 50 KI-Vorlagen für alle gängigen Schreibaufgaben",
        "10 Personalisierte Vorlagen + Vorlagen Bearbeiten",
        "24 Stunden Live Chat Support",
        "Bis zu 100 Texte / Monat"
      ],
      "mostPopular": true
    },
    "Creator": {
      "text": [
        "Bestes KI-Modell",
        `Alle Funktionen des KI-Textgenerators`,
        "Mehr als 50 KI-Vorlagen für alle gängigen Schreibaufgaben",
        "100 Personalisierte Vorlagen + Vorlagen Bearbeiten",
        "24 Stunden Live Chat Support",
        "Unlimitierte Texterstellung"
      ],
      "mostPopular": false
    }
  }

  // Functions and Event Handlers
  const getPlanByRole = (currentRole) => {
    return Object.values(products).find((plan) => plan?.name === currentRole && plan?.active);
  };

  const getPricesByPeriod = (period, prices) => {
    return prices?.find(price => price.priceData.interval === period && price.priceData.active === true);
  };

  const calculateDiscountedPrice = (originalPrice) => {
    if (!discountInfo || !discountInfo.active) {
      return formatPrice(originalPrice);
    }
    
    console.log(discountInfo, formatPrice(originalPrice * (1 - discountInfo.percent_off / 100)));
    
    let discountedPrice;
    if (discountInfo.percent_off) {
      discountedPrice = originalPrice * (1 - discountInfo.percent_off / 100);
    } else if (discountInfo.amount_off) {
      discountedPrice = Math.max(0, originalPrice - discountInfo.amount_off / 100);
    } else {
      discountedPrice = originalPrice;
    }

    return formatPrice(discountedPrice);
  };

  const formatPrice = (price) => {
    const roundedPrice = Math.round(price * 100) / 100;
    return roundedPrice % 1 === 0 ? roundedPrice.toString() : roundedPrice.toFixed(2);
  };

  /* UseEffect Hooks */
  useEffect(() => {
    const fetchProducts = async () => {
      const snapshot = await firebase
        .firestore()
        .collection("products")
        .where("active", "==", true)
        .get();

      const prod = {};
      for (let productDoc of snapshot.docs) {
        prod[productDoc.id] = productDoc.data();
        const priceSnapShot = await productDoc.ref.collection("prices").get();
        prod[productDoc.id].prices = priceSnapShot.docs.map(priceDoc => ({
          priceId: priceDoc.id,
          priceData: priceDoc.data(),
        }));
      }

      setProducts(prod);
    };

    const fetchDiscountInfo = async () => {
      if (discount_code) {
        try {
          const couponSnapshot = await firebase
            .firestore()
            .collection("coupons")
            .where("id", "==", discount_code)
            .get();

          if (!couponSnapshot.empty) {
            const couponData = couponSnapshot.docs[0].data();
            setDiscountInfo({
              active: couponData.active,
              percent_off: couponData.coupon.percent_off,
              amount_off: couponData.coupon.amount_off,
            });
          } else {
            console.error('Invalid discount code');
          }
        } catch (error) {
          console.error('Error fetching discount information:', error);
        }
      }
    };

    fetchProducts();
    fetchDiscountInfo();
  }, [discount_code]);

  useEffect(() => {
    if (CTA === "Jetzt anmelden") {
      setCardOrder(["Creator", "Starter", "Kostenlos"]);
    } else {
      setCardOrder(["Starter", "Creator"]);
    }
    if (products?.length !== 0) {
      setTimeout(() => {
        setEnabled(!enabled);
        setLoading(false);
      }, 1000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  if (loading === true || products?.length === 0)
    return (
      <SpinningLoader />
    );

  return (
    <section className={`py-10 ${bgImage && "lg:py-20"} relative`} id="preis">
      <div className={`${bgImage && "bg-dots-pattern py-8"}`}>
        <Container>
          <h3 className="text-2xl text-center md:text-4xl font-semibold mb-5">
            Preise
          </h3>
          <Switch.Group as="div" className="flex justify-center items-center">
            <Switch.Label as="span" className="mr-3">
              <span className="text-sm sm:text-base font-medium">
                Monatliches Abo
              </span>
            </Switch.Label>
            <Switch
              checked={enabled}
              onChange={setEnabled}
              className={`${enabled ? "bg-secondary-500" : "bg-primary-500"}
            relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
            >
              <span
                aria-hidden="true"
                className={`${enabled ? "translate-x-5" : "translate-x-0"}
                pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
            <Switch.Label as="span" className="ml-3">
              <span className="text-sm sm:text-base font-medium">
                Spare 50% mit einem jährlichen Abo
              </span>
            </Switch.Label>
          </Switch.Group>
          <div className="mt-10 md:mt-16 align-center">
            <ul className={`grid grid-cols-1 lg:grid-cols-2 gap-20 ${cardOrder[2] ? "xl:grid-cols-3" : "xl:grid-cols-2"}`}>
              <li key={`plan-2`}>
                <PlanCard
                  createUserAndCheckout={createUserAndCheckout3}
                  id={
                    getPricesByPeriod(
                      enabled === true ? "year" : "month",
                      getPlanByRole(cardOrder[0])?.prices
                    )?.priceId
                  }
                  mostPopular={prices[cardOrder[0]].mostPopular}
                  enabled={enabled}
                  title2={getPlanByRole(cardOrder[0])}
                  price={
                    getPricesByPeriod(
                      enabled === true ? "year" : "month",
                      getPlanByRole(cardOrder[0])?.prices
                    )?.priceData?.unit_amount / 100
                  }
                  discountedPrice={calculateDiscountedPrice(
                    getPricesByPeriod(
                      enabled === true ? "year" : "month",
                      getPlanByRole(cardOrder[0])?.prices
                    )?.priceData?.unit_amount / 100
                  )}
                  planIncludes={prices[cardOrder[0]].text}
                  words={getPlanByRole(cardOrder[0])?.words}
                  CTA={CTA}
                />
              </li>
              <li key={`plan-1`}>
                <PlanCard
                  createUserAndCheckout={createUserAndCheckout2}
                  id={
                    getPricesByPeriod(
                      enabled === true ? "year" : "month",
                      getPlanByRole(cardOrder[1])?.prices
                    )?.priceId
                  }
                  mostPopular={prices[cardOrder[1]].mostPopular}
                  enabled={enabled}
                  title2={getPlanByRole(cardOrder[1])}
                  price={
                    getPricesByPeriod(
                      enabled === true ? "year" : "month",
                      getPlanByRole(cardOrder[1])?.prices
                    )?.priceData?.unit_amount / 100
                  }
                  discountedPrice={calculateDiscountedPrice(
                    getPricesByPeriod(
                      enabled === true ? "year" : "month",
                      getPlanByRole(cardOrder[1])?.prices
                    )?.priceData?.unit_amount / 100
                  )}
                  planIncludes={prices[cardOrder[1]].text}
                  words={getPlanByRole(cardOrder[1])?.words}
                  CTA={CTA}
                />
              </li>
              {cardOrder[2] && (
                <li key={`plan-0`}>
                  <PlanCard
                    createUserAndCheckout={createUserAndCheckout1}
                    id={
                      getPricesByPeriod(
                        enabled === true ? "year" : "month",
                        getPlanByRole(cardOrder[2])?.prices
                      )?.priceId
                    }
                    mostPopular={prices[cardOrder[2]].mostPopular}
                    enabled={enabled}
                    title2={getPlanByRole(cardOrder[2])}
                    price={
                      getPricesByPeriod(
                        enabled === true ? "year" : "month",
                        getPlanByRole(cardOrder[2])?.prices
                      )?.priceData?.unit_amount / 100
                    }
                    discountedPrice={calculateDiscountedPrice(
                      getPricesByPeriod(
                        enabled === true ? "year" : "month",
                        getPlanByRole(cardOrder[2])?.prices
                      )?.priceData?.unit_amount / 100
                    )}
                    planIncludes={prices[cardOrder[2]].text}
                    words={getPlanByRole(cardOrder[2])?.words}
                    CTA={CTA}
                  />
                </li>
              )}
            </ul>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default PlansSection;
