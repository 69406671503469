const CustomSuccessIcon = () => (
    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="26" height="27" rx="3" fill="#00A977" fillOpacity="0.1" />
        <g clip-path="url(#clip0_38_64)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7 13C7 11.4087 7.63214 9.88258 8.75736 8.75736C9.88258 7.63214 11.4087 7 13 7C14.5913 7 16.1174 7.63214 17.2426 8.75736C18.3679 9.88258 19 11.4087 19 13C19 14.5913 18.3679 16.1174 17.2426 17.2426C16.1174 18.3679 14.5913 19 13 19C11.4087 19 9.88258 18.3679 8.75736 17.2426C7.63214 16.1174 7 14.5913 7 13ZM12.6576 15.568L16.112 11.2496L15.488 10.7504L12.5424 14.4312L10.456 12.6928L9.944 13.3072L12.6576 15.568Z" fill="#00A977" />
        </g>
        <defs>
            <clipPath id="clip0_38_64">
                <rect width="12" height="12" fill="white" transform="translate(7 7)" />
            </clipPath>
        </defs>
    </svg>

);

export default CustomSuccessIcon;