// TextArea.tsx
import React, { TextareaHTMLAttributes, InputHTMLAttributes } from 'react';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  handleInputRef: (ref: HTMLTextAreaElement | null) => void;
  onChangeHandler: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  height?: number;
}

const TextArea: React.FC<TextAreaProps> = ({ label, handleInputRef, onChangeHandler, height = 20, ...props }) => {
  return (
    <div className="flex flex-col mb-3.5">
      <textarea
        placeholder=""
        maxLength={2000}
        className={`bg-white rounded placeholder:text-xs placeholder:text-gray-1000 border-[#EDEDED] h-${height}`}
        name={label}
        required id="theme"
        rows={8}
        ref={handleInputRef}
        onChange={onChangeHandler}
        {...props}
      />
    </div>
  );
};

interface SingleLineInputProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    handleInputRef: (ref: HTMLInputElement | null) => void;
    onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  }
  
  const SingleLineInput: React.FC<SingleLineInputProps> = ({ label, handleInputRef, onChangeHandler, ...props }) => {
    return (
    <div className="flex flex-col mb-3.5">
      <input
        type="text"
        ref={handleInputRef}
        onChange={onChangeHandler}
        name={label}
        className="bg-white rounded placeholder:text-xs placeholder:text-gray-1000 border-[#EDEDED] h-11"
        {...props}
      />
    </div>
    );
  };
  
  export { SingleLineInput, TextArea };