import React, { useState } from "react";
import Modal from "react-modal";
import { useForm, SubmitHandler } from "react-hook-form";
import { CloseIcon } from "../../../src/assets/Icons";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../src/config/fbConfig";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import SpinningLoader from "../../../src/components/loader/mini_spinning_loader";

const sendFeedback = httpsCallable(functions, "sendFeedback");
const sendTestimonial = httpsCallable(functions, "sendTestimonial");

type Props = {
  onClose: () => void;
  isTestimonial?: boolean;
};

interface FormData {
  feedback: string;
  starcnt: number;
  consent: boolean;
  position?: string;
}

const ReviewModal: React.FC<Props> = ({ onClose, isTestimonial = false }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<FormData>({ defaultValues: { starcnt: 0, consent: false } });
  const queryClient = useQueryClient();
  const [starCnt, setStarCnt] = useState<number>(0);
  const [hoveredStar, setHoveredStar] = useState<number>(0);
  const currentStarCnt = watch("starcnt");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleModalClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const updateReviewMutation = useMutation({
    mutationFn: async (data: FormData) => {
      if (isTestimonial) {
        await sendTestimonial({
          testimonial: data.feedback,
          starcnt: data.starcnt,
          position: data.position,
        });
      } else {
        await sendFeedback({ feedback: data.feedback });
      }

      let successToastText = isTestimonial
        ? "Wir haben dein Testimonial empfangen und überprüfen es gerade."
        : "Wir haben dein Feedback empfangen und überprüfen es gerade.";

      toast.success(successToastText, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    },
    onSuccess: async () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ["get-reward-data"] });
        setIsSubmitting(false);
        onClose();
      }, 2000);
    },
  });

  const submitReview: SubmitHandler<FormData> = async (data) => {
    if (isTestimonial && (data.starcnt === 0 || !data.consent)) {
      if (data.starcnt === 0) {
        alert("Bitte gib eine Bewertung in Sternen an.");
      }
      if (!data.consent) {
        alert("Bitte stimme zu, dass dein Testimonial veröffentlicht werden darf.");
      }
      return;
    }
    setIsSubmitting(true);
    updateReviewMutation.mutate(data);
  };

  return (
    <Modal
      isOpen={true}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-50 z-40"
    >
      <div
        className="fixed inset-0 flex items-center justify-center z-50"
        onClick={onClose}
      >
        <div
          className="pl-[30px] pt-0 pb-6 m-2 z-30 bg-white rounded-lg text-center overflow-hidden container mx-auto relative"
          style={{
            maxWidth: `${
              window.innerWidth < 672 ? window.innerWidth - 45 + "px" : "680px"
            }`,
            maxHeight: `${
              window.innerWidth < 672
                ? window.innerHeight - 220 + "px"
                : window.innerHeight
            }`,
          }}
          onClick={handleModalClick}
        >
          {isSubmitting ? (
            <div className="flex flex-col items-center justify-center">
                <div className="m-12 w-24 h-24">
                  <SpinningLoader />
                </div>
              <div className="mt-4 text-lg font-semibold text-gray-700">
                {isTestimonial ? "Testimonial wird gesendet..." : "Feedback wird gesendet..."}
              </div>
            </div>
          ) : (
            <>
              <div className="flex justify-between w-full relative">
                <span className="text-[27px] mt-6 font-semibold pb-5">
                  {isTestimonial ? "Teile dein Testimonial" : "Teile dein Feedback"}
                </span>
                <button
                  id="closeModal"
                  onClick={onClose}
                  className="absolute right-5 md:right-4 top-4 m-0 md:mb-4 md:w-6 md:h-6 w-5 h-5"
                >
                  <CloseIcon />
                </button>
              </div>
              <form onSubmit={handleSubmit(submitReview)} className="mr-[30px]">
                <div className="col-span-2">
                  {isTestimonial && (
                    <div className="flex justify-between mt-4">
                      <div className="flex flex-col items-start w-full md:w-1/2">
                        <label
                          className="text-left text-sm font-medium"
                          htmlFor="starcnt"
                        >
                          Sterne
                        </label>
                        <div className="flex items-center">
                          {[1, 2, 3, 4, 5].map((star) => (
                            <button
                              key={star}
                              type="button"
                              onClick={() => {
                                setStarCnt(star);
                                setValue("starcnt", star);
                              }}
                              onMouseOver={() => setHoveredStar(star)}
                              onMouseOut={() => setHoveredStar(0)}
                              className={`text-4xl ${
                                star <= (hoveredStar || starCnt)
                                  ? "text-amber-400"
                                  : "text-neutral-400"
                              }`}
                            >
                              ★
                            </button>
                          ))}
                        </div>
                        {errors.starcnt && (
                          <span className="text-red-500 text-xs">
                            Dies ist ein Pflichtfeld
                          </span>
                        )}
                      </div>
                      <div className="flex flex-col items-start w-full md:w-1/2 ml-4">
                        <label
                          className="text-left text-sm font-medium"
                          htmlFor="position"
                        >
                          Position
                        </label>
                        <input
                          type="text"
                          id="position"
                          {...register("position", { required: true })}
                          placeholder="Deine Position"
                          className="mt-1 block w-full rounded-md border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 text-xs placeholder-neutral-300"
                        />
                        {errors.position && (
                          <span className="text-red-500 text-xs">
                            Dieses Feld ist erforderlich
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="flex flex-col items-start mt-4 w-full">
                    <label
                      className="text-left text-sm font-medium"
                      htmlFor="feedback"
                    >
                      {isTestimonial ? "Testimonial" : "Feedback"}
                    </label>
                    <textarea
                      id="feedback"
                      {...register("feedback", { required: true })}
                      placeholder={
                        isTestimonial
                          ? "Teile deine Erfahrung mit Uns..."
                          : "Gib uns Feedback..."
                      }
                      className="mt-1 block w-full rounded-md border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 text-xs placeholder-neutral-300 h-32"
                    />
                    {errors.feedback && (
                      <span className="text-red-500 text-xs">
                        Dieses Feld ist erforderlich
                      </span>
                    )}
                  </div>
                   {isTestimonial && (
                    <div className="flex items-start mt-4 w-full">
                      <input
                        type="checkbox"
                        id="consent"
                        {...register("consent", { required: true })}
                        className="mr-2"
                      />
                      <label
                        htmlFor="consent"
                        className="text-left text-sm font-medium"
                      >
                        Ich stimme zu, dass mein Testimonial mit meinem Namen und
                        meiner Position auf der robowriter.de Website
                        veröffentlicht werden darf.
                      </label>
                      {errors.consent && (
                        <span className="text-red-500 text-xs block">
                          Diese Zustimmung ist erforderlich
                        </span>
                      )}
                    </div>
                  )}
                </div>
                <button
                  type="submit"
                  className="mt-4 bg-[#8043F9] text-white w-full rounded-[5px] px-2.5 py-[11px]"
                >
                  Senden
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ReviewModal;