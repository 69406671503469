import Footer from "./Footer";
import NavbarOutSite from "./NavbarOutSide";

type Props = {
  children: React.ReactNode;
};

const LayoutOutSide = (props: Props) => {
  return (
    <section className={`min-h-screen flex flex-col`}>
      <NavbarOutSite />
      <div className="flex-1">{props.children}</div>
      <Footer />
    </section>
  );
};

export default LayoutOutSide;
