import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useForm, SubmitHandler } from "react-hook-form";
import { CloseIcon, CopyIcon } from "../../../src/assets/Icons";
import {auth, functions, firestore} from "../../../src/config/fbConfig";
import {httpsCallable} from "firebase/functions";
import {toast} from "react-toastify";
import firebase from "../../config/fbConfig";

type Props = {
    onClose: () => void;
};

interface FormData {
    name: string;
    email: string;
}

const inviteUser_py = httpsCallable(functions, "inviteUser");

const InviteUserModal: React.FC<Props> = ({ onClose }) => {
    const [privateLink, setPrivateLink] = useState<string>("");
    const [inviteCnt, setInviteCnt] = useState(0);

    const { register, handleSubmit, formState: { errors } } = useForm<FormData>();

    const handleModalClick = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    const getInvitedFriends = async ()=>{
        const userSnapshot = await firebase.firestore().collection('users').doc(auth.currentUser!.uid).get();
        const userData = userSnapshot.data()
        setInviteCnt(Number(userData?.rewards?.inviteCnt || 0));
    }

    const generatePrivateLink = () => {
        const refId = auth.currentUser!.uid;
        let prefix = "https://app.robowriter.de"
        if (process.env.REACT_APP_PROJECT_ID?.includes("tst-robowriter")) {
            prefix = "https://tst-robowriter.web.app";
        }
        const link = prefix + `/sign-up?req=${refId}`;
        setPrivateLink(link);
    };

    const inviteUser: SubmitHandler<FormData> = (data) => {
        inviteUser_py({ name: data.name, email: data.email });
        // Show toast immediately after feedback submission
        toast.success(`Wir haben ${data.name} eine Einladungs-E-Mail von Dir geschickt. Vielen Dank für deine Empfehlung. Die Texte werden Dir gut geschrieben, sobald ${data.name} einen Account erstellt.`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        onClose();
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(privateLink);
        alert("Link copied to clipboard!");
    };

    useEffect(() => {
        generatePrivateLink();
        getInvitedFriends();
    }, []);

    return (
        <Modal
            isOpen={true}
            className="fixed inset-0 flex items-center justify-center z-50"
            overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-50 z-40"
        >
            <div
                className="fixed inset-0 flex items-center justify-center z-50"
                onClick={onClose}
            >
                <div
                    className="pl-[30px] pt-0 pb-6 m-2 z-30 bg-white rounded-lg text-center overflow-hidden container mx-auto relative"
                    style={{
                        maxWidth: `${window.innerWidth < 780 ? window.innerWidth - 45 + 'px' : "740px"}`,
                        maxHeight: `${window.innerWidth < 672 ? window.innerHeight - 180 + 'px' : window.innerHeight}`,
                    }}
                    onClick={handleModalClick}
                >
                    <div className="flex justify-between w-full relative">
                        <span className="text-[27px] mt-6 font-semibold pb-6">Lade deine Freunde ein</span>
                        <button
                            id="closeModal"
                            onClick={onClose}
                            className="absolute right-4 top-4 m-0 md:mb-4 md:w-6 md:h-6"
                        >
                            <CloseIcon />
                        </button>
                    </div>

                    <div className="border border-neutral-200 rounded-[8px] mr-[30px] p-6 pt-4">
                        <p className="text-start text-base font-medium">Für jeden eingeladenen, neuen aktiven Nutzer erhältst du täglich 2 zusätzliche Texte. Du hast bereits {inviteCnt}/5 Freunde erfolgreich eingeladen.</p>

                        <div className="mt-6 mb-8">
                            <div>
                                <label className="text-left block text-sm font-medium" htmlFor="private-link">Dein persönlicher Einladungslink</label>
                                <div className="relative mt-1">
                                    <input
                                        type="text"
                                        id="private-link"
                                        value={privateLink}
                                        placeholder="Generate your private link"
                                        readOnly
                                        className="block w-full rounded-md border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 text-xs text-black pr-8"
                                    />
                                    <button
                                        type="button"
                                        onClick={copyToClipboard}
                                        className="absolute inset-y-0 right-0 px-3 py-1 rounded-md text-sm h-full flex items-center"
                                    >
                                        <CopyIcon />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <hr className="my-6 border-t border-neutral-200" />

                        <form onSubmit={handleSubmit(inviteUser)}>
                            <div className="grid grid-cols-2 gap-5 mb-8">
                                <div className="col-span-2 md:col-span-1">
                                    <label className="text-left block text-sm font-medium" htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        {...register("name", { required: true })}
                                        placeholder="Max Mustermann"
                                        className="mt-1 block w-full rounded-md border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 text-xs placeholder-neutral-300"
                                    />
                                    {errors.name && <span className="text-red-500 text-xs">Dieses Feld ist ein Pflichtfeld</span>}
                                </div>

                                <div className="col-span-2 md:col-span-1">
                                    <label className="text-left block text-sm font-medium" htmlFor="email">E-mail Addresse</label>
                                    <input
                                        type="text"
                                        id="email"
                                        {...register("email", { required: true })}
                                        placeholder="user@robowriter.de"
                                        className="mt-1 block w-full rounded-md border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 text-xs placeholder-neutral-300"
                                    />
                                    {errors.email && <span className="text-red-500 text-xs">Dies ist ein Pflichtfeld</span>}
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="bg-[#8043F9] text-white w-full rounded-[5px] px-2.5 py-[11px] transition-colors duration-200"
                            >
                                Einladung Abschicken
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default InviteUserModal;