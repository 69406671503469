import GenericModal from "./GenericModal";
import { UserData } from "../../utils/userUtils";

type Props = {
  userData: UserData;
  showModal: boolean;
  handleShowModal: () => void;
};

const WordsModal = (props: Props) => {
  const { showModal, handleShowModal } = props; // Destructure showModal from props

  return (
    <GenericModal
      isOpen={showModal}
      title={`So viele Texte kannst Du heute noch generieren`}
      content={['Diese Anzeige zeigt dir, wie viele Texte du heute noch generieren kannst. Solltest du einmal mehr benötigen, kannst du jederzeit unter Einstellungen upgraden.'
      ]}
      buttonText="Alles klar, vielen Dank 😊"
      image="/assets/images/writing_hand.svg"
      imagePlacement="top"
      onClose={() => { }}
      onButtonClick={() => { }}
      closeModal={handleShowModal}
    />
  )
}

export default WordsModal;