import { useEffect, useState } from "react";
import LayoutInSide from "../../layouts/LayoutInSide";
import SpinningLoader from "../../components/loader/spinning_loader";
import { getAllUserData, UserData } from "../../utils/userUtils";
import { toast } from "react-toastify";
import {
    doc,
    getDoc,
    firestore,
    onSnapshot,
    addDoc,
    serverTimestamp,
    deleteDoc,
    updateDoc,
    auth
} from "../../config/fbConfig";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faXmark, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { usePostHog } from 'posthog-js/react';

// Modular Fireabse SDK imports
import { collection } from 'firebase/firestore';
import { useShareableData } from "../../providers/ShareableDataProvider";
import { UserGuideStepEnum } from "../../consts";

const PersonaPage = () => {

    // Constants
    const max_input_length = 400;

    // State variables
    const [loading, setLoading] = useState(false);
    const [personas, setPersonas] = useState([{ "id": "", "name": "", "personatext": "" }])
    const [userData, setUserData] = useState<UserData>({ "userData": "test", "productData": "test" });
    const [input, setInput] = useState('');
    const [textLength, setTextLength] = useState(0);
    const [name, setName] = useState('');
    const [editId, setEditId] = useState('');
    const [showIndex, setShowIndex] = useState(-1);
    const { showOnBoarding, completeOnBoardingStep } = useShareableData();

    // load instances
    const posthog = usePostHog();

    // Event handlers
    const handleNameFieldChange = (e: any) => {
        setName(e.target.value);
    }
    const handleTextFieldChange = (e: any) => {
        setInput(e.target.value);

        const totalWordsCount = e.target.value
            .split(" ")
            .filter((word: string) => word !== "").length;

        setTextLength(totalWordsCount);
    }

    const loadUserData = async () => {
        setLoading(true)
        if (auth.currentUser) {
            const retrievedUserData = await getAllUserData(auth.currentUser.uid);
            setUserData(retrievedUserData);

            const writeDataDocRef = collection(firestore, "users", auth.currentUser?.uid!, "personas");

            onSnapshot(writeDataDocRef, (snapshot: any) => {
                const personasData = snapshot.docs.map((doc: any) => ({ id: doc.id, ...doc.data() }));
                personasData.sort((a: any, b: any) => (a.created_at > b.created_at) ? 1 : -1);
                setPersonas(personasData);
            });
        } else {
            console.error("User document not found");
        }
        setLoading(false)
    };

    const submitNewPersona = async (event: any) => {
        event.preventDefault();
        if (personas.some(persona => persona.name === name)) {
            toast.error("Ein Tonfall mit dem Namen existiert bereits. Bitte wähle einen anderen Namen für deinen Tonfall.", {
                position: "top-right",
                autoClose: 7000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            if (auth.currentUser && name !== "" && input !== "") {
                // here writedata and writedataId can be any unique value of your choosing
                const personasCollectionRef = collection(firestore, "users", auth.currentUser.uid, "personas");
                await addDoc(personasCollectionRef, {
                    "name": name,
                    "personatext": input,
                    "created_at": serverTimestamp()
                });
                setInput("")
                setName("")
                setEditId("")
                posthog.capture('tonfall_page:new_tonfall', {
                    tonfall_name: name,
                    tonfall: input,
                });
                if(showOnBoarding){
                    completeOnBoardingStep(UserGuideStepEnum.TONE)
                }
            }
        }
    }

    const SubmitEditedPersona = async (event: any) => {
        event.preventDefault();
        if (auth.currentUser) {
            const personasCollectionRef = doc(firestore, "users", auth.currentUser.uid, "personas", editId);
            await updateDoc(personasCollectionRef, {
                "name": name,
                "personatext": input,
                "created_at": serverTimestamp()
            });
            setInput("")
            setName("")
            setEditId("")
        }
    }

    const deletePersonaById = (id: string) => async (event: React.MouseEvent) => {
        if (auth.currentUser) {
            const personaDocRef = doc(firestore, "users", auth.currentUser.uid, "personas", id);
            await deleteDoc(personaDocRef);
            if (editId !== "") {
                setInput("")
                setName("")
                setEditId("")
            }
        }
    }

    const editPersonaById = (id: string) => async (event: React.MouseEvent) => {
        if (auth.currentUser) {
            const personaDocRef = doc(firestore, "users", auth.currentUser.uid, "personas", id);
            const personaDoc = await getDoc(personaDocRef);
            const personaDocData = personaDoc.data();
            setName(personaDocData?.name || "")
            setInput(personaDocData?.personatext || "")
            setEditId(personaDoc?.id || "")
        }
    }

    /* UseEffect Functions */

    useEffect(() => {
        loadUserData();
    // Should run once, on component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading === true)
        return (
            <SpinningLoader />
        );

    return (
        <LayoutInSide
            userData={userData}
        >
            <div className="mx-auto items-center justify-center">
                <h3 className="text-[32px] font-outfit font-semibold text-textColor tracking-[0.32px]">Tonfall
                    Übersicht</h3>
                {personas.length > 0 &&
                    <p className="mt-8">Hier findest du eine Liste deiner Tonfalltexte:</p>}
                <ul className="list-disc list-inside">
                    <div>
                        {personas.map((item, index) => (
                            <div key={index} className="accordion w-dropdown bg-white shadow rounded-lg my-2">
                                <div className="flex items-center justify-between p-4">
                                    <p className="text-xl font-semibold">{item.name}</p>
                                    <div className="space-x-2  items-center">
                                        <FontAwesomeIcon
                                            className="m-2 h-5 w-5 cursor-pointer text-secondary-500 hover:text-secondary-800"
                                            onClick={() => showIndex === index ? setShowIndex(-1) : setShowIndex(index)}
                                            icon={showIndex === index ? faChevronUp : faChevronDown}
                                        />
                                        <FontAwesomeIcon
                                            className="m-2 h-5 w-5 cursor-pointer text-secondary-500 hover:text-secondary-800"
                                            onClick={editPersonaById(item.id)}
                                            icon={faPenToSquare}
                                        />
                                        <FontAwesomeIcon
                                            className="m-2 h-5 w-5 cursor-pointer text-secondary-500 hover:text-secondary-800"
                                            onClick={deletePersonaById(item.id)}
                                            icon={faXmark}
                                        />
                                    </div>
                                </div>
                                {showIndex === index && (
                                    <div className="w-dropdown-list p-4 border-t border-gray-200">
                                        <div className="whitespace-pre-line text-gray-600">{item.personatext}</div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </ul>
                <p className="mt-8">Füge hier Text ein, welcher deinem gewünschten Tonfall entspricht. So kannst du in jeder Vorlage den Tonfall auswählen, der zu dir passt.
                    Lade hier ein Beispiel wie du normalerweise schreibst, wie
                    einen Post, eine E-Mail, oder eine Beschreibung hoch.</p>
                <p className="mt-8">Du kannst deinen Tonfall dann in jeder Vorlage unter dem Dropdown Menu "Tonfall" auswählen.</p>
                <div className="mt-8 w-full justify-center">
                    <label htmlFor="theme" className=" text-[13px] font-medium flex items-center gap-2 mb-3">Tonfall Titel</label>
                    <div className="flex flex-col mb-3.5">
                        <input
                            value={name}
                            onChange={handleNameFieldChange}
                            placeholder="Benenne deinen Tonfall"
                            className="bg-white rounded placeholder:text-xs placeholder:text-gray-1000 border-[#EDEDED]"
                        />
                    </div>
                    <div className="flex flex-col mb-3.5">
                        <div className="w-full flex justify-between">
                            <label htmlFor="theme"
                                className=" text-[13px] font-medium flex items-center gap-2 mb-3">Tonfall Beispiel/Beschreibung</label>
                            <p className="text-[13px] text-gray-1000 font-medium font-poppins">
                                <span>{textLength}</span>/{max_input_length}</p>
                        </div>
                        <textarea
                            value={input}
                            maxLength={10000}
                            onChange={handleTextFieldChange}
                            placeholder="Füge hier einen Text in deinem Stil ein, den Robowriter sich merken soll."
                            className="bg-white rounded placeholder:text-xs placeholder:text-gray-1000 border-[#EDEDED] h-40"
                        />
                    </div>
                    <div className="flex justify-center">
                        {(editId === "") ? (
                            <button
                                className="flex items-center m-2 py-2 h-12 px-12 w-18 rounded bg-secondary text-white text-lg hover:bg-secondary-600"
                                onClick={submitNewPersona}>Tonfall erstellen
                            </button>)
                            :
                            (
                                <div className="flex">
                                    <button
                                        className="flex m-2 items-center py-2 h-12 px-12 w-18 rounded bg-secondary text-white text-lg hover:bg-secondary-600"
                                        onClick={SubmitEditedPersona}>Persona anpassen
                                    </button>
                                    <button
                                        className="flex items-center m-2 py-2 h-12 px-12 w-18 rounded bg-white border-[1px] border-secondary hover:bg-secondary-600 hover:text-white text-secondary"
                                        onClick={() => {
                                            setEditId("");
                                            setInput("");
                                            setName("");
                                        }}
                                    >
                                        Bearbeiten Abbrechen
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </LayoutInSide>
    );
};

export default PersonaPage;
