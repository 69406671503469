import LayoutOutSide from "../../layouts/LayoutOutSide";
import ImprintSection from "../../components/sections/ImprintSection";

const ImprintPage = () => {
  return (
    <LayoutOutSide>
      <ImprintSection></ImprintSection>
    </LayoutOutSide>
  );
};

export default ImprintPage;
