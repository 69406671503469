import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "../../components/Container";
import NavbarOutSide from "../../layouts/NavbarAuth";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import Footer from "../../layouts/Footer";
import { toast } from "react-toastify";
import {Helmet} from "react-helmet";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success("Passwort-Reset E-Mail wurde verschickt.", {
          position: "top-right",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/sign-in");
      })
      .catch((err) => {
        toast.success("Passwort-Reset E-Mail wurde verschickt.", {
          position: "top-right",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/sign-in");
      });
  };

  return (
    <div className="application">
    <Helmet>
      <meta charSet="utf-8" />
      <title>Robowriter KI - Passwort zurücksetzen</title>
      <meta name="description" content="Robowriter ChatGPT - Ein KI-Schreibassistent für jede Anwendung" />
    </Helmet>
    <main className="min-h-screen flex flex-col">
      <NavbarOutSide />
      <section className="flex-1 flex flex-col sm:justify-top lg:justify-center">
        <Container>

          <div className="flex flex-wrap justify-center items-center w-full">
            <div className="w-full order-2 lg:order-1 lg:w-[45%] mb-4">
              <div className="text-white rounded-2xl">
                <h1 className="text-primary font-semibold text-left lg:text-[28px] text-[24px] leading-[40px] max-w-[300px] lg:max-w-none mx-0 mb-4">
                Passwort zurücksetzen
                </h1>
              </div>
              <form onSubmit={handleSubmit}>
                    <ul className="grid grid-cols-1 lg:grid-cols-2 gap-6 font-roboto">
                      <li className="lg:col-span-2">
                        <div>
                          <label
                            htmlFor="email"
                            className="mb-1 block text-base font-medium text-primary"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="block w-full border-0 p-3 font-light text-primary-500 bg-desertStrom placeholder-gray-500 focus:ring-0 rounded-lg text-sm"
                            placeholder="email@email.com..."
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </li>
                      <li className="lg:col-span-2">
                        <button
                          type="submit"
                          className="btn-default w-full rounded-lg font-Outfit bg-secondary text-lg h-12 text-white font-bold tracking-[0.16px] border-2 border-secondary transition-all duration-200 ease-in-out px-8 hover:bg-transparent hover:text-secondary"
                        >
                          Passwort zurücksetzen
                        </button>
                      </li>
                    </ul>
                  </form>
                </div>
              </div>
        </Container>
      </section>
    </main>
    <Footer />
  </div>
);
};

export default ForgotPasswordPage;
