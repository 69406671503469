import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

interface Props {
  onSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchTemplates = ({ onSearch }: Props) => {
  return (
    <>
      <form className="relative w-full" onSubmit={(e) => e.preventDefault()}>
        <input
          type="search"
          placeholder="Vorlagen Durchsuchen"
          className="w-full bg-gray-100 hover:bg-white focus:bg-white font-light border-gray-200 placeholder:text-gray-400 rounded-lg pl-4 pr-10"
          onChange={onSearch}
        />
        <button
          type="button"
          className="absolute px-3 top-[0.625rem] right-0 text-gray-400 hover:text-blue-500"
        >
          <MagnifyingGlassIcon className="block h-5 w-5" />
        </button>
      </form>
    </>
  );
};

export default SearchTemplates;
