import Container from "../Container";
import ImprintCard from "../cards/ImprintCard";

const ContactSection = () => {
  return (
    <section className="py-8">
      <Container>
          <ImprintCard />
      </Container>
    </section>
  );
};

export default ContactSection;
