export enum UserGuideStepEnum {
    CREATE_ACCOUNT = 'CREATE_ACCOUNT',
    AI_TEXT = 'AI_TEXT',
    CHAT = 'CHAT',
    TONE = 'TONE',
    TEMPLATE = 'TEMPLATE',
    NEWSLETTER = 'NEWSLETTER'
}

export enum ModalTypeEnum {
    OnboardingModal = 'OnboardingModal',
    InviteUserModal = 'InviteUserModal',
    TestimonialModal = 'TestimonialModal',
    FeedbackModal = 'FeedbackModal'
}

export const completeOnBoardingStepMessagesMap = {
    [UserGuideStepEnum.CREATE_ACCOUNT]: '',
    [UserGuideStepEnum.AI_TEXT]: 'Herzlichen Glückwunsch, Du hast Deinen ersten KI-Text generiert. Hier sind 3 Bonus-Texte für Dich 🎉',
    [UserGuideStepEnum.CHAT]: 'Herzlichen Glückwunsch, Du hast zum ersten Mal erfolgreich den KI-Chat genutzt. Hier sind 3 Bonus-Texte für Dich 🎉',
    [UserGuideStepEnum.TONE]: 'Dein erster eigener Tonfall. Du kannst diesen nun jederzeit in einer der Vorlagen auswählen. Hier sind 3 Bonus-Texte für Dich. 🎉',
    [UserGuideStepEnum.TEMPLATE]: 'Du hast Deine erste eigene Vorlage erstellt. Hier sind 3 Bonus-Texte für Dich 🎉 ',
    [UserGuideStepEnum.NEWSLETTER]: '',
}

export const updateBonusMap = {
    [UserGuideStepEnum.CREATE_ACCOUNT]: '',
    [UserGuideStepEnum.AI_TEXT]: 'dynamic_template_used',
    [UserGuideStepEnum.CHAT]: 'ai_chat_used',
    [UserGuideStepEnum.TONE]: 'tonality_used',
    [UserGuideStepEnum.TEMPLATE]: 'template_used',
    [UserGuideStepEnum.NEWSLETTER]: '',
}