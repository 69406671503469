import { Link } from "react-router-dom";
import Container from "../components/Container";
import { HashLink } from "react-router-hash-link";
import { Icon } from "@iconify/react";

const footerLinks = [
  { text: "Home", link: "/" },
  { text: "Funktionen", link: "/#features" },
  { text: "Preis", link: "/#preis" },
];
const termsOfUseLinks = [
  { text: "AGBs", link: "https://www.iubenda.com/nutzungsbedingungen/91682523" },
  { text: "Datenschutzerklärung", link: "https://www.iubenda.com/privacy-policy/91682523" },
  { text: "Impressum", link: "https://app.robowriter.de/imprint" },
  { text: "© 2023 Robowriter", link: "#" },
];
const socialMediaLinks = [
  { icon: "dashicons:linkedin", link: "https://linkedin.com/company/robowriter" },
];
const Footer = () => {
  return (
    <footer className="bg-grad lg:py-[100px] sm:py-[70px] py-[36px] ">
      <Container>
        <div className="flex flex-wrap -mx-4">
          <div className="lg:w-2/5 w-full lg:order-1 order-2 px-4">
            <ul className="flex flex-wrap sm:gap-[50px] gap-[30px] lg:justify-start justify-center">
              {footerLinks.map((linkItem: any, index: any) => (
                <li key={index}>
                  <HashLink
                    to={linkItem.link} smooth
                    className="text-[#202356] text-[15px] tracking-[0.15px] hover:text-secondary   transition-[0.3s]"
                  >
                    {linkItem.text}
                  </HashLink>
                </li>
              ))}
            </ul>
            <div className="lg:flex flex-wrap gap-[30px] mt-[40px] hidden">
              <a
                href="mailto:kontakt@robowriter.de"
                className="font-semibold text-[14px] text-[#202356]  hover:text-secondary   transition-[0.3s]"
              >
                <span>kontakt@robowriter.de</span>
              </a>
              <a
                href="tel:+49 15752471391"
                className="font-semibold text-[14px] text-[#202356]"
              >
                <span>0157 52471391</span>
              </a>
            </div>
          </div>
          <div className="lg:w-1/5 w-full lg:order-2 order-1 px-4 text-center lg:mb-0 mb-[38px]">
            <img src="assets/images/logo-small.svg" alt="" className="inline-flex" />
          </div>
          <div className="lg:w-2/5 w-full lg:order-3 order-3 px-4">
            <div className="flex flex-col flex-wrap">
              <ul className="flex item-center lg:justify-end justify-center gap-4 mb-[40px] lg:order-1 order-2">
                {socialMediaLinks.map((socialLink, index) => (
                  <li key={index}>
                    <Link
                      to={socialLink.link}
                      className="text-[24px] text-[#202356] hover:text-secondary   transition-[0.3s]"
                    >
                      <Icon icon={socialLink.icon} />
                    </Link>
                  </li>
                ))}
              </ul>
              <ul className="flex flex-wrap xl:gap-[50px] gap-[25px] lg:justify-end justify-center lg:mt-0 my-[24px] lg:order-2 order-1">
                {termsOfUseLinks.map((termsLink: any, index: any) => (
                  <li
                    key={index}
                    className={`${index === termsOfUseLinks.length - 1
                      ? "lg:block hidden"
                      : ""
                      }`}
                  >
                    <Link
                      to={termsLink.link}
                      className={`text-[#202356] text-[14px] tracking-[0.15px] hover:text-secondary   opacity-[0.7] hover:opacity-1 transition-[0.3s]  ${index === termsOfUseLinks.length - 1
                        ? "lg:block hidden pointer-events-none"
                        : "underline"
                        }`}
                    >
                      {termsLink.text}
                    </Link>
                  </li>
                ))}
              </ul>
              <p className="text-[#202356] text-[14px] tracking-[0.15px] hover:text-secondary   opacity-[0.7] hover:opacity-1 transition-[0.3s] lg:hidden block text-center order-3 mt-[0px] ">
                © 2023 Robowriter
              </p>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;