import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import LayoutInSide from "../../layouts/LayoutInSide";
import SpinningLoader from "../../components/loader/spinning_loader";
import { getUserTier, getAllUserData, UserData } from "../../utils/userUtils";
import {
  LockClosedIcon,
} from "@heroicons/react/24/solid";
import { httpsCallable } from "firebase/functions";
import firebase, { functions } from "../../config/fbConfig";
import { toast } from "react-toastify";
import { usePostHog } from 'posthog-js/react';
import { auth } from "../../config/fbConfig";
import { DeleteIcon } from "../../assets/Icons";
import { useShareableData } from "../../providers/ShareableDataProvider";
import { UserGuideStepEnum } from "../../consts";

const getDynamicTemplate = httpsCallable(functions, "getDynamicTemplate");

const DynamicTemplateDetailPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('query');

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [input, setInput] = useState(query);
  const [allowNewTemplate, setAllowNewTemplate] = useState(false);
  const [userData, setUserData] = useState<UserData>({ "userData": "test", "productData": "test" });
  const [curDynamicTemplates, setCurDynamicTemplates] = useState(0);
  const [maxDynamicTemplates, setMaxDynamicTemplates] = useState(0);
  const [textLength, setTextLength] = useState(0);
  const { showOnBoarding, completeOnBoardingStep } = useShareableData();

  const max_input_length = 100;
  const posthog = usePostHog();
  const handleTextFieldChange = (e: any) => {
    setInput(e.target.value);

    const totalWordsCount = e.target.value
      .split(" ")
      .filter((word: string) => word !== "").length;

    setTextLength(totalWordsCount);
  }

  const onStart = async (event: any) => {
    event.preventDefault();
    const prompt = input;
    if (textLength > max_input_length) {
      toast.warning(
        `Sie haben die maximale Wort-Zahl zur Beschreibung Ihrer Vorlage überschritten. Bitte beschränken Sie sich auf ${max_input_length} Wörter.`,
        {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    } else {
      setLoading(true)
      try {
        getDynamicTemplate({ prompt: prompt });

        // Posthog event dynamic_template_detail_page:create_template
        posthog.capture('dynamic_template_detail_page:create_template', { prompt: prompt })

        if (showOnBoarding) {
          completeOnBoardingStep(UserGuideStepEnum.TEMPLATE)
        };

        navigate("/templates/", { state: { "showDynTemplatePopUp": true } });
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
  }

  const loadUserData = async () => {
    setLoading(true)
    if (auth.currentUser) {
      const userDocRaw = await firebase.firestore().collection("users").doc(auth.currentUser.uid)
      let templateCollection = await userDocRaw.collection("templates").get();
      let templateCollectionSize = templateCollection.size
      setCurDynamicTemplates(templateCollectionSize)
      const retrievedUserData = await getAllUserData(auth.currentUser.uid);
      setUserData(retrievedUserData);
      const userTier = await getUserTier(auth.currentUser.uid);
      const maxDynamicTemplates = userTier.dynamictemplates
      setMaxDynamicTemplates(maxDynamicTemplates)
      if (maxDynamicTemplates > templateCollectionSize) {
        setAllowNewTemplate(true)
      }
    } else {
      console.error("User document not found");
    }
    setLoading(false)
  };

  const handleUpgradeToast = () => {
    toast.warning(
      "Sie haben bereits alle Ihre dynamischen Vorlagen erstellt. Bitte nehmen Sie ein Upgrade vor, um weitere dynamische Vorlagen generieren zu können.",
      {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
  };

  useEffect(() => {
    loadUserData();
  }, []);

  if (loading === true)
    return (
      <SpinningLoader />
    );

  return (
    <>
      <LayoutInSide
        userData={userData}
      >
        <div className="grid grid-cols-1 p-2">
          {/* First Column, should take up one third of space */}
          <div className="col-span-1">
            {/* Header & Subtitle */}
            <div className="mb-8">
              <h1 className=" md:text-[32px] te xt-xl font-Outfit font-semibold">Persönliche Vorlage Erstellen</h1>
              <p className="md:text-[15px] text-[12px] text-gray-4000">Beschreiben Sie genau, was Sie benötigen und lassen Sie sich live eine neue Vorlage erstellen.</p>
            </div>

            {/* Dynamic Template Limit Indicator */}
            {maxDynamicTemplates < 1000 ? (
              <div className="md:text-[15px] text-[12px] text-gray-4000 mb-4">
                Sie können noch {maxDynamicTemplates - curDynamicTemplates} / {maxDynamicTemplates} Vorlagen erstellen
              </div>
            ) : ("")}
            {
              <span className="text-[13px] text-gray-1000 font-medium font-poppins">
                Wörter: {textLength}/{max_input_length}
              </span>
            }

            {/* Input Field */}
            <div className="flex flex-col mb-3.5 relative">
              <textarea
                onChange={handleTextFieldChange}
                maxLength={2000}
                placeholder="Beschreiben Sie in diesem Abschnitt, was Sie verfassen möchten. Robowriter erstellt eine maßgeschneiderte Vorlage für Sie und berücksichtigt dabei, welche Informationen Sie bereitstellen sollten."
                className="bg-white rounded placeholder:text-xs placeholder:text-gray-1000 border-[#EDEDED] h-40"
                value={input || ''}
              />
              {input !== '' && (
                <button
                  className="absolute top-[5px] right-[5px]"
                  style={{
                    visibility: input !== '' ? 'visible' : 'hidden'
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setInput('');
                  }}
                >
                  <DeleteIcon />
                </button>
              )}
            </div>
            <div className="text-center">
              {allowNewTemplate ? (
                <button
                  // type="submit"
                  onClick={onStart} // bind onStart function to onClick event
                  className="py-2 h-12 px-12 rounded bg-secondary text-white font-poppins text-lg font-semibold hover:bg-secondary-600"
                >
                  Start
                </button>
              ) : (
                <button
                  onClick={handleUpgradeToast}
                  title="Vorlagen können ausschließlich mit einem Starter oder Creator Plan bearbeitet werden. Bitte upgraden Sie."
                  className="justify-center whitespace-nowrap inline-flex items-center py-2 h-12 px-12 rounded bg-secondary text-white font-poppins text-lg font-semibold hover:bg-secondary-600"
                >
                  <span className="mr-1">
                    <LockClosedIcon className="h-5 w-5" />
                  </span>
                  <span>Start</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </LayoutInSide>
    </>
  );
};

export default DynamicTemplateDetailPage;
