import React, { useEffect } from "react";

interface Props {
    url: string;
}

const Redirect: React.FC<Props> = ({ url }) => {
    useEffect(() => {
        window.location.href = url;
    }, [url]);

    return <h5>Redirecting...</h5>;
};

export default Redirect;