import React, { useEffect, useState } from 'react';
import LayoutInSide from '../../layouts/LayoutInSide';
import { UserData, getAllUserData } from '../../../src/utils/userUtils';
import { auth, doc, firestore, getDoc } from '../../config/fbConfig';
import { RewardBrowser, RewardFeedback, RewardFriend, RewardInvite, RewardPremium, RewardTestimonial, RewardVideo, RewardsCortex, RewardsDiscord, RewardsSocial } from '../../../src/assets/Icons';
import { Link } from 'react-router-dom';
import { useModal } from '../../../src/providers/ModalProvider';
import { ModalTypeEnum } from '../../../src/consts';
import { useQuery } from '@tanstack/react-query';

const RewardPage: React.FC = () => {
    const [userData, setUserData] = useState<UserData>({ 'userData': 'test', 'productData': 'test' });
    const { setActiveModal } = useModal();

    const { data: rewardData } = useQuery({
        queryKey: ['get-reward-data'],
        queryFn: async () => {
            const userDocRef = doc(firestore, 'users', auth.currentUser!.uid);
            const docSnapshot = await getDoc(userDocRef);
            const data = docSnapshot.data();
            if (data && data.rewards) {
                return data.rewards;
            } else {
                return null;
            }
        }
    });

    const testimonial = rewardData?.testimonial;
    const feedback = rewardData?.feedback;

    const loadUserData = async () => {
        let retrievedUserData = await getAllUserData(auth.currentUser!.uid);
        setUserData(retrievedUserData);
    };

    useEffect(() => {
        loadUserData();
    }, []);

    const isButtonDisabled = (modalType: ModalTypeEnum) => {
        if (modalType === ModalTypeEnum.TestimonialModal) {
            return !!testimonial;
        }
        if (modalType === ModalTypeEnum.FeedbackModal) {
            return !!feedback;
        }
        return false;
    };

    const getButtonClassNames = (modalType: ModalTypeEnum) => {
        const baseClassNames = 'hover:bg-secondary-500 hover:text-white font-semibold border border-neutral-300 w-fit rounded-[5px] px-2.5 py-[11px] text-xs sm:text-sm';
        const disabledClassNames = 'opacity-50 cursor-not-allowed';
        return `${baseClassNames} ${isButtonDisabled(modalType) ? disabledClassNames : ''}`;
    };

    return (
        <LayoutInSide userData={userData}>
            <div className='flex flex-col px-4 sm:px-0'>
                <span className='text-3xl font-semibold'>Zusätzliche Texte</span>
                <span className='text-base text-neutral-500 font-medium mt-3 mb-6 '>
                    Du brauchst mehr Texte? Erledige die folgenden Aufgaben und erhalte zusätzliche Texte
                </span>
                <div className='bg-white rounded-[6px] px-6 pb-6 md:pr-9 sm:px-9 pb-9 pt-7'>
                    <p className='text-lg font-semibold mb-6'>Hauptaufgaben</p>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5'>
                        {rewardItems.map((item, index) => (
                            <div key={index} className="relative rounded-[6px] p-[1px] before:absolute before:inset-0 before:rounded before:bg-gradient-to-r before:from-blue-600 before:to-secondary-500 before:p-[1px] before:box-border">
                                <div className="relative z-1 bg-white p-4 pb-5 h-full rounded-[3px] flex flex-col">
                                    <div className='flex justify-between'>
                                        <div className='h-9 w-9 bg-secondary-500 flex justify-center items-center rounded-[3px]'>
                                            {item.icon}
                                        </div>
                                        <span className='w-10/12 font-semibold text-sm md:text-base pl-2 leading-5'>{item.title}</span>
                                    </div>
                                    <div className='border-b border-neutral-200 my-3'></div>
                                    <p className='text-neutral-500 text-xs md:text-[15px] flex-grow mb-8'>{item.description}</p>
                                    {!item.modal ? (
                                        <button className='bg-secondary-500 text-white font-semibold w-fit rounded-[5px] px-2.5 py-[11px] transition-colors duration-200 text-xs sm:text-sm'>
                                            <Link to={'/upgrade'}>
                                                {item.buttonText}
                                            </Link>
                                        </button>
                                    ) : (
                                        <div className="relative">
                                            {isButtonDisabled(item.modal) && (
                                                <div className="absolute -top-2 -left-2 z-10 w-4 h-4 bg-green-500 rounded-full flex items-center justify-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                                    </svg>
                                                </div>
                                            )}
                                            <button
                                                className={getButtonClassNames(item.modal)}
                                                onClick={() => setActiveModal(item.modal)}
                                                disabled={isButtonDisabled(item.modal)}
                                            >
                                                {item.buttonText}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
            {/*<div className='mt-8 px-4 sm:px-0'>*/}
            {/*    <div className='bg-white rounded-[6px] pt-7'>*/}
            {/*        <p className='text-lg font-semibold mb-2 px-6 py-1 sm:px-9 sm:py-2'>Nebenaufgaben</p>*/}
            {/*        <div className='divide-y divide-neutral-200 flex flex-col'>*/}
            {/*            {rewardItemsTasks.map((item, index) => (*/}
            {/*                <div key={index} className="flex flex-col md:flex-row items-start md:items-center justify-between border-neutral-200 py-5 px-6 sm:px-9">*/}
            {/*                    <div className="flex items-center">*/}
            {/*                        <div className='h-9 w-9 bg-[#F2ECFE] flex justify-center items-center rounded-[3px] mr-3 sm:mr-4'>*/}
            {/*                            {item.icon}*/}
            {/*                        </div>*/}
            {/*                        <div className='w-10/12'>*/}
            {/*                            <span className='font-semibold text-base'>{item.title}</span>*/}
            {/*                            <p className='text-neutral-500 text-sm'>{item.description}</p>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="flex items-center justify-between md:justify-end w-full md:w-4/12 mt-2 sm:mt-0">*/}
            {/*                        {item.points && (*/}
            {/*                            <div className='flex items-center justify-center'>*/}
            {/*                                <img src="/assets/images/reward-coin.png" className='w-6 h-5 mr-1' alt="" />*/}
            {/*                                <span className='mr-5 sm:mr-10 lg:mr-16 font-semibold'>{item.points} </span>*/}
            {/*                            </div>*/}
            {/*                        )}*/}
            {/*                        <Link to={'/'} className={`hover:bg-secondary-500 hover:text-white text-center tracking-normal font-semibold border border-neutral-300 w-[150px] text-xs sm:text-sm rounded-[5px] px-2.5 py-[11px] transition-colors duration-200 ${!item.points ? 'ml-auto' : ''} mt-2 md:mt-0`}                                    >*/}
            {/*                            {item.buttonText}*/}
            {/*                        </Link>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            ))}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </LayoutInSide>
    );
};

const rewardItems = [
    {
        title: 'Unbegrenzter Service: Robowriter-Premium',
        description: 'Befreie Dich von allen Beschränkungen und erhalte automatisch unser bestes KI-Modell',
        icon: <RewardPremium />,
        buttonText: 'Preise anzeigen',
    },
    {
        title: '2 Texte für Dich und Deine Freunde',
        description: 'Jeder geworbene Freund kann täglich zwei Texte mehr generieren – und Du auch!',
        icon: <RewardInvite />,
        buttonText: 'Freund einladen',
        modal: ModalTypeEnum.InviteUserModal
    },
    {
        title: '10 Texte für Dein Testimonial',
        description: 'Sende uns Dein Testimonial zu und erhalte einmalig 10 Texte',
        icon: <RewardTestimonial />,
        buttonText: 'Testimonial senden',
        modal: ModalTypeEnum.TestimonialModal,
    },
    {
        title: 'Sende uns Dein Feedback zu',
        description: 'Du möchtest uns etwas mitteilen? Sende uns Dein Feedback und erhalte einmalig 5 Texte',
        icon: <RewardFeedback />,
        buttonText: 'Feedback senden',
        modal: ModalTypeEnum.FeedbackModal,
    }
];

const rewardItemsTasks = [
    {
        title: 'Download the browser extension',
        description: 'User you need an over 4000 websites and platforms',
        icon: <RewardBrowser />,
        buttonText: 'Install Extensions',
        points: null,
    },
    {
        title: 'Send a post testimonial video',
        description: 'Grab a 14-day Premium trial for free',
        icon: <RewardVideo />,
        buttonText: 'Get Premium',
        points: null,
    },
    {
        title: 'Invite more friends to earn extra daily creations',
        description: 'Earn 10 extra daily creations as soon as your friend registered for .',
        icon: <RewardFriend />,
        buttonText: 'Invite Friends',
        points: 10,
    },
    {
        title: 'Introduce yourself in Discord',
        description: 'Get 5 extra daily creations by joining our Discord community.',
        icon: <RewardsDiscord />,
        buttonText: 'Join Discord',
        points: 5,
    },
    {
        title: 'Graduate from Cortex Academy',
        description: 'Get 5 extra daily creations by finishing Academy',
        icon: <RewardsCortex />,
        buttonText: 'Start Academy',
        points: 5,
    },
    {
        title: 'Follow us on our social media channels',
        description: 'Get 2 extra daily creations for each platform you follow us on',
        icon: <RewardsSocial />,
        buttonText: 'Follow Us',
        points: 2,
    }
];

export default RewardPage;