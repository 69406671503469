import React, { ReactNode, createContext, useContext, useState } from 'react'
import OnboardingModal from '../layouts/Modal/OnboardingModal';
import { useNavigate } from 'react-router-dom';
import { ModalTypeEnum } from '../consts';
import InviteUserModal from '../../src/layouts/Modal/InviteUserModal';
import ReviewModal from '../../src/layouts/Modal/ReviewModal';

interface IModalContext {
    setActiveModal: React.Dispatch<React.SetStateAction<ModalTypeEnum | null>>
}

const ModalContext = createContext<IModalContext>({
    setActiveModal: () => { }
})

interface IModalProps {
    onClose: () => void;
}

const ModalMap = {
    [ModalTypeEnum.OnboardingModal]: {
        Component: OnboardingModal
    },
    [ModalTypeEnum.InviteUserModal]: {
        Component: InviteUserModal
    },
    [ModalTypeEnum.TestimonialModal]: {
        Component: (props: IModalProps) => <ReviewModal {...props} isTestimonial/>
    },
    [ModalTypeEnum.FeedbackModal]: {
        Component: (props: IModalProps) => <ReviewModal {...props}/>
    }
}

interface ModalProviderProps {
    children: ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
    const [activeModal, setActiveModal] = useState<ModalTypeEnum | null>(null);
    const isFromVerifyPage = window.location.pathname === "/welcome";

    const navigate = useNavigate();

    const onClose = () => {
        if (isFromVerifyPage) {
            navigate("/templates");
        }
        setActiveModal(null)
    }

    const { Component } = activeModal ? ModalMap[activeModal] : { Component: () => null };

    return (
        <ModalContext.Provider value={{ setActiveModal }}>
            <>
                {activeModal && <Component onClose={onClose} />}
                {children}
            </>
        </ModalContext.Provider>
    )
}

export const useModal = () => useContext(ModalContext);