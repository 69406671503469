import React from "react";
import Modal from "react-modal";
import OnboardingContent from "../../components/modal-content/OnboardingContent";
import WelcomeContent from "../../components/modal-content/WelcomeContent";

type Props = {
  onClose: () => void;
};

const OnboardingModal = ({ onClose }: Props) => {
  const isFromVerifyPage = window.location.pathname === "/welcome";

  const handleModalClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <Modal
      isOpen={true}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-50 z-40"
    >
      <div
        className="fixed inset-0 flex items-center justify-center z-50"
        onClick={onClose}
      >
        <div
          className="m-2 z-30 bg-white rounded-lg text-center overflow-hidden container mx-auto relative flex flex-col md:flex-row"
          style={{
            maxWidth: `${window.innerWidth < 672 ? window.innerWidth - 45 + 'px' : "672px"}`,
            maxHeight: `${window.innerWidth < 672 ? window.innerHeight - 220 + 'px' : window.innerHeight}`,
          }}
          onClick={handleModalClick}
        >
          {!isFromVerifyPage && (
            <OnboardingContent onClose={onClose} />
          )}
          {!!isFromVerifyPage && (
            <WelcomeContent onClose={onClose} />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default OnboardingModal;
