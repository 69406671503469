import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import { PostHogProvider } from 'posthog-js/react';

// swiper CSS
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// Tailwind CSS
import "./index.css";

// Posthog options
// We use the memory persistence option to avoid setting cookies, that way we dont
// need to differentiate between users that have approved cookies and those that haven't
const options = {
  api_host: process.env.REACT_APP_POSTHOG_HOST,
  persistence: "memory" as "memory",
  ui_host: process.env.REACT_APP_POSTHOG_UI_HOST,
};

const container = document.getElementById('root');

if (!container) {
  throw new Error('Could not find root element');
}

const root = createRoot(container!);

root.render(
    <React.StrictMode>
      <PostHogProvider 
        apiKey={process.env.REACT_APP_POSTHOG_KEY}
        options={options}
      >
            <App />
      </PostHogProvider>
    </React.StrictMode>
);
