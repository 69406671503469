import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import firebase from "../config/fbConfig";
import { functions, auth } from "../config/fbConfig";
import { httpsCallable } from "firebase/functions";

export const useManageStripeSubscription = () => {
  const navigate = useNavigate();
  const [subscriptionState, setSubscriptionState] = useState("");

  const getSubscriptionStatus = async () => {
    await firebase
      .firestore()
      .collection("users")
      .doc(auth.currentUser!.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          let data = doc.data();
          if (data && !data.freetrial) {
            setSubscriptionState("subscribed");
          } else {
            setSubscriptionState("free");
          }
        }
      })
      .catch((error) => console.error("Error reading document", error));
  };

  useEffect(() => {
    getSubscriptionStatus();
  }, []);

  const manageStripeSubscription = async () => {
    if (subscriptionState === "subscribed") {
      const functionRef = httpsCallable(functions, "ext-firestore-stripe-payments-createPortalLink");
      const functionResult = await functionRef({ returnUrl: window.location.origin });
      if (functionResult) {
        const stripeData = functionResult.data as { url: string };
        if (stripeData.url) {
          window.location.assign(stripeData.url);
        } else {
          console.error("Stripe data does not contain 'url' property");
        }
      }
    } else if (subscriptionState === "free") {
      navigate("/upgrade");
    }
  };

  return manageStripeSubscription;
};