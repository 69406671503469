import Container from "../components/Container";
import NavbarInSide from "./NavbarInSide";
import Sidebar from "./Sidebar";
import FooterInside from "./FooterInside";
import { UserData } from "../utils/userUtils";
import React, { useState, useEffect } from 'react';
import {
  firestore,
  collection,
  doc,
  onSnapshot,
  auth
} from "../config/fbConfig";

// Crisp Chat
import { Crisp } from "crisp-sdk-web";

type Props = {
  children: React.ReactNode;
  onSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  userData: UserData;
};

const LayoutInSide = (props: Props) => {

  // State Variables
  const [openSidebar, setSidebarState] = useState(false);
  const [localUserData, setLocalUserData] = useState(props.userData);

  /* useEffect Hooks */

  /**
   * This useEffect hook is responsible for subscribing to the user's document in Firestore.
   * If the user is authenticated and their document exists, it sets the local user data state.
   * If the user's document does not exist, it logs a message.
   * If the user is not authenticated, it logs a message.
   * It also cleans up the subscription when the component unmounts.
  */
  useEffect(() => {
    let unsubscribe: () => void;

    if (auth.currentUser && auth.currentUser.uid) {
      const userDocRef = doc(collection(firestore, "users"), auth.currentUser.uid);

      unsubscribe = onSnapshot(userDocRef, (userDocSnap: any) => {
        if (userDocSnap.exists()) {
          const userDocData = userDocSnap.data();
          setLocalUserData({ "userData": userDocData, "productData": props.userData.productData })
        } else {
          // Handle the case when the document does not exist
          // For example, you can set localUserData to null or show a message
          // setLocalUserData(null);
          console.log('User document does not exist.');
        }
      });

    } else {
      // Handle the case when auth.uid is falsy (user is not authenticated)
      // For example, you can redirect the user to a login page or show a message
      console.log('User is not authenticated.');
    }

    return () => {
      if (unsubscribe) {
        unsubscribe(); // Clean up subscription on unmount
      }
    }
    // Run this effect whenever the product data changes
  }, [props.userData.productData]);

  // Initialize Crisp chat when the user is authenticated
  auth.onAuthStateChanged(function (user) {
    if (user) {
      // Check if user is in paid tier
      // Initialize Crisp chat
      Crisp.configure("8e95355d-1778-4151-9a31-b75b01e0cc89",
        {
          // Options ref: https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/npm/#available-methods
          // Set Autoload to false, so that it only loads when the user is authenticated
          autoload: false,
          locale: 'de'
        }
      );

      // Set user data for Crisp chat
      // Show the chat only for paid users
      if (localUserData.userData.email === undefined || localUserData.userData.freetrial === true) {
        // If the data is not loaded yet, return.
        // Otherwise errors will pop up in the console.
        return;
      }
      Crisp.user.setEmail(localUserData.userData.email);
      Crisp.user.setNickname(localUserData.userData.fullname);

      // Set TokenID for Crisp chat
      Crisp.setTokenId(user.uid);

      Crisp.load();
    }
  });

  /**
   * This useEffect hook enables toggling the sidebar by clicking outside of it.
   */
  useEffect(() => {
    openSidebar ? document.body.classList.add("show-mobile-sidebar") : document.body.classList.remove("show-mobile-sidebar");
  }, [openSidebar]);

  // Function to check if the footer should be displayed
  const shouldShowFooter = () => {
    const path = window.location.pathname;
    return path.endsWith("/templates") || path.endsWith("/settings");
  };

  // Effect to handle Crisp chat visibility based on pathname
  useEffect(() => {
    if (typeof $crisp !== 'undefined') {
      if (window.location.pathname.startsWith('/chat')) {
        $crisp.push(["do", "chat:hide"]);
      } else {
        $crisp.push(["do", "chat:show"]);
      }
    }
  }, []);

  return (
    <>
      <section className={`min-h-screen flex flex-col md:flex-row bg-light`}>
        <div className="hidden md:block">
          <Sidebar userData={localUserData} />
        </div>
        {openSidebar && <div className="fixed inset-0 z-10 bg-gray-600 opacity-50" onClick={() => setSidebarState(false)} />}
        <div className="flex-1 flex flex-col">
          <NavbarInSide onSearch={props.onSearch} userData={localUserData} setSidebarState={setSidebarState} />
          <Container>
            <div className="md:hidden">
              <Sidebar userData={localUserData} />
            </div>
            {props.children}
          </Container>
          {shouldShowFooter() && <FooterInside />}
        </div>
      </section>
    </>
  );
};

export default LayoutInSide;