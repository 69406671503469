import PlansSection from "../../components/sections/PlansSection";
import { useState, useEffect } from "react";
import firebase, { auth } from "../../config/fbConfig";
import Container from "../../components/Container";
import NavbarOutSide from "../../layouts/NavbarAuth";
import Footer from "../../layouts/FooterInside";
import { useNavigate, useLocation } from "react-router-dom";
import SpinningLoader from "../../components/loader/spinning_loader";
import { usePostHog } from 'posthog-js/react';
import { logoutUser } from "../../utils/loginUtils";

const UpgradePage = () => {
  const [loading, setLoading] = useState(false);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const posthog = usePostHog();
  // Get discount code from URL if it exists
  const searchParams = new URLSearchParams(location.search);
  const discountCode = searchParams.get('discount');

  // Posthog event upgrade_page:pageview
  useEffect(() => {
    posthog.capture('upgrade_page:pageview')
  // Disable the eslint warning here, since we only want to run this once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // should only run once

  useEffect(() => {
    const checkSubscription = async () => {
      const uid = auth?.currentUser?.uid;
      if (uid) {
        const userRef = firebase.firestore().collection("users").doc(uid);
        const userDoc = await userRef.get();
        const userData = userDoc.data();

        if (userData?.subscriptionStatus === "active") {
          setHasActiveSubscription(true);
          return;
        }

        const subscriptionsRef = userRef.collection("subscriptions");
        const subscriptionsSnapshot = await subscriptionsRef.get();

        const hasActiveSubscription = subscriptionsSnapshot.docs.some(
          (doc) => doc.data().status === "active"
        );

        setHasActiveSubscription(hasActiveSubscription);
      }
    };
    checkSubscription();
  }, []);

  const upgradeUser = async (priceId: any) => {
    setLoading(true);

    // Posthog event upgrade_page:upgrade_user_function
    posthog.capture('upgrade_page:upgrade_user_function', { price_id: priceId })

    const uid = auth?.currentUser?.uid;
    const urlPrefix = window.location.href.includes("localhost")
      ? "http://localhost:3000"
      : window.location.href.includes("tst-robowriter")
        ? "https://tst-robowriter.web.app/"
        : "https://app.robowriter.de";

    // Create a new Checkout Session for the order
    const checkoutSessionRef = await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .collection("checkout_sessions").doc()

    const checkoutSessionData: {
      price: any;
      allow_promotion_codes: boolean;
      automatic_tax: boolean;
      success_url: string;
      cancel_url: string;
      created: firebase.firestore.FieldValue;
      promotion_code?: string | string;
    } = {
      price: priceId,
      allow_promotion_codes: true,
      automatic_tax: true,
      success_url: `${urlPrefix}/success?priceId=${priceId}`,
      cancel_url: `${urlPrefix}`,
      created: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // Add discount code to checkout session if it exists
    if (discountCode) {
      checkoutSessionData.promotion_code = discountCode;
    }

    await checkoutSessionRef.set(checkoutSessionData);

    const unsubscribe = checkoutSessionRef.onSnapshot(async (snap: any) => {
      const { error, url }: any = snap.data();
      if (error) {
        alert(error.message);
      }
      if (url) {
        window.location.href = url;
      }
    });
    setTimeout(() => {
      unsubscribe();
      console.warn("Timeout: checkout session not found.");
      logoutUser(auth, navigate);
      navigate("/404");
    }, 30000);
  };


  if (loading === true)
    return (
      <SpinningLoader />
    );

  return (
    <>
      <div>
        <main className="min-h-screen flex flex-col">
          <NavbarOutSide />
          <section className="flex-1 py-8 flex flex-col justify-center">
            <Container>
              {hasActiveSubscription ? (
                <div className="text-center">
                  <div className="text-xl font-semibold text-secondary-500 mb-4">
                    Dieses Angebot ist leider abgelaufen.
                  </div>
                  <button
                    onClick={() => navigate('/templates')}
                    className="px-4 py-2 bg-secondary-500 text-white rounded-md hover:bg-secondary-600 transition-colors"
                  >
                    Zurück zu den Vorlagen
                  </button>
                </div>
              ) : (
                <PlansSection
                  bgImage={false}
                  CTA={"Jetzt Upgraden"}
                  createUserAndCheckout1={upgradeUser}
                  createUserAndCheckout2={upgradeUser}
                  createUserAndCheckout3={upgradeUser}
                  discount_code={discountCode}
                />
              )}
              <div className="md:mb-16" />
            </Container>
          </section>
          <Footer />
        </main>
      </div>
    </>
  );
};

export default UpgradePage;