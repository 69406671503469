const CustomErrorIcon = () => (
    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="26" height="27" rx="3" fill="#FEF7E0" />
        <g clip-path="url(#clip0_38_21)">
            <path d="M13 7C13.5508 7 14.082 7.07031 14.5938 7.21094C15.1055 7.35156 15.582 7.55469 16.0234 7.82031C16.4648 8.08594 16.8691 8.39844 17.2363 8.75781C17.6035 9.11719 17.918 9.52148 18.1797 9.9707C18.4414 10.4199 18.6426 10.8984 18.7832 11.4062C18.9238 11.9141 18.9961 12.4453 19 13C19 13.5508 18.9297 14.082 18.7891 14.5938C18.6484 15.1055 18.4453 15.582 18.1797 16.0234C17.9141 16.4648 17.6016 16.8691 17.2422 17.2363C16.8828 17.6035 16.4785 17.918 16.0293 18.1797C15.5801 18.4414 15.1016 18.6426 14.5938 18.7832C14.0859 18.9238 13.5547 18.9961 13 19C12.4492 19 11.918 18.9297 11.4062 18.7891C10.8945 18.6484 10.418 18.4453 9.97656 18.1797C9.53516 17.9141 9.13086 17.6016 8.76367 17.2422C8.39648 16.8828 8.08203 16.4785 7.82031 16.0293C7.55859 15.5801 7.35742 15.1016 7.2168 14.5938C7.07617 14.0859 7.00391 13.5547 7 13C7 12.4492 7.07031 11.918 7.21094 11.4062C7.35156 10.8945 7.55469 10.418 7.82031 9.97656C8.08594 9.53516 8.39844 9.13086 8.75781 8.76367C9.11719 8.39648 9.52148 8.08203 9.9707 7.82031C10.4199 7.55859 10.8984 7.35742 11.4062 7.2168C11.9141 7.07617 12.4453 7.00391 13 7ZM13.75 16V14.5H12.25V16H13.75ZM13.75 13.75V10H12.25V13.75H13.75Z" fill="#F9AB00" />
        </g>
        <defs>
            <clipPath id="clip0_38_21">
                <rect width="12" height="12" fill="white" transform="translate(7 7)" />
            </clipPath>
        </defs>
    </svg>
);

export default CustomErrorIcon;