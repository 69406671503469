import { useCallback } from "react";
import LayoutOutSide from "../../layouts/LayoutOutSide";

const ErrorPage = () => {
  const handleGoBack = useCallback(() => {
    window.location.href = "/";
  }, []);

  return (
    <LayoutOutSide>
      <section className="min-h-screen bg-dots-pattern flex justify-center items-center py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 text-white items-center bg-gradient-to-tl md:bg-gradient-to-l to-purple-500 via-blue-400 from-blue-500 rounded-2xl py-8 px-4 md:px-8">
          {/* Logo Section */}
          <div className="flex justify-center md:justify-start items-center w-full">
            <img
              src="/assets/images/logo-no-text-white.svg"
              alt="Robowriter logo"
              className="h-96 w-96 mx-auto"
            />
          </div>

          {/* Error Message Section */}
          <div className="space-y-4 text-left">
            <h2 className="text-4xl font-bold mb-4">Error 404</h2>
            <p className="text-lg mb-4">Robowriter kann Ihre Anfrage nicht finden.</p>
            <button className="bg-purple-800 hover:bg-purple-900 text-white py-2 px-4 rounded" onClick={handleGoBack}>
              Startseite
            </button>
          </div>
        </div>
      </section>
    </LayoutOutSide>
  );
};

export default ErrorPage;