import Paths from "./Paths";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { usePostHog } from 'posthog-js/react'
import Modal from 'react-modal';
import { auth } from '../src/config/fbConfig';
import { useState } from 'react';
import SpinningLoader from './components/loader/spinning_loader';

const App = () => {
  Modal.setAppElement('#root');
  const [isAuthStateReady, setIsAuthStateReady] = useState(false);

  // Access Posthog instance from context
  const posthog = usePostHog();

  // Identify user when auth changes
  auth.onAuthStateChanged((user) => {
    if (user !== null) {
      posthog.identify(user.uid);
      posthog.people.set({
        $email: user.email,
        $name: user.displayName,
        $user_id: user.uid,
      });
    }
  });

  // This keeps track if the auth state is ready
  // If the app is rendered before the auth state is ready, the user will be redirected to the login page
  auth.authStateReady().then(async() => {
    setIsAuthStateReady(true);
  });

  return (
    <div className="text-primary-500 scroll-smooth">
      <ToastContainer />
      {isAuthStateReady ? <Paths /> : <SpinningLoader />}
    </div>
  );
};

export default App;
