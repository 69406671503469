import { useState, useEffect } from 'react'; // Import useState
import GenericModal from "./GenericModal";
import { UserData } from "../../utils/userUtils";
import { httpsCallable } from "firebase/functions";
import {
  functions,
} from "../../config/fbConfig";
import { toast } from "react-toastify";

type Props = {
  userData: UserData;
  showModal: boolean;
  handleShowModal: () => void;
};

// Function to handle the closeModalClick
function call_move_to_creations(callback: () => void) {
  // Call the firebase function "move_to_creations"
  const move_to_creations = httpsCallable(functions, "move_to_creations", { timeout: 300000 });

  // Call the function
  move_to_creations()
    .then((result: any) => {
      if (result.data.success === true) {
        callback(); // Call the callback function to handle success
      }
      else {
        // Render toast or error notice
        console.log("Failed to move to creations");
        console.log(result.data);
        toast.error("Etwas ist schief gelaufen. Bitte kontaktiere den Support per Chat.", {
          position: "top-right",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    })
    .catch((error) => {
      console.error(error);
      toast.error("Etwas ist schief gelaufen. Bitte kontaktiere den Support per Chat.", {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
}

const CreationsTransitionModal = (props: Props) => {
  const {showModal, handleShowModal } = props;
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const [userTier, setUserTier] = useState("Kostenlos");

  useEffect(() => {
    // Check the user's subscription status and set the user tier accordingly
    if (props.userData.userData.unlimitedwords === true) {
      setUserTier("Creator");
    } else if (props.userData.userData.freetrial === false) {
      setUserTier("Starter");
    }
  }, [props.userData.userData.unlimitedwords, props.userData.userData.freetrial]); // Dependencies array


  const handleButtonClick = () => {
    setIsLoading(true); // Set loading to true
    call_move_to_creations(() => {
      setIsLoading(false); // Set loading to false upon completion
      handleShowModal(); // Close the modal
    });
  };

  // Function to get content based on user subscription tier
  const getContentByTier = (tier: string): string[] => {
    switch (tier) {
      case 'Kostenlos':
        return [
          'Ab sofort berechnen wir keine einzelnen Worte mehr, sondern ganze Texte. Diese werden täglich wieder aufgefrischt.',
          'Im kostenlosen Plan erhältst du 3 Texte pro Tag.'
        ];
      case 'Starter':
        return [
          'Ab sofort berechnen wir keine einzelnen Worte mehr, sondern ganze Texte. Diese werden monatlich wieder aufgefrischt.',
          'Als Starter kannst du jetzt 100 Texte mit dem besten Robowriter Modell generieren. Als Dankeschön für deine Treue haben wir deinen Account permanent auf Unlimitierte Texterstellung umgestellt!',
        ];
      case 'Creator':
        return [
          'Ab sofort berechnen wir keine einzelnen Worte mehr, sondern ganze Texte. Diese werden monatlich wieder aufgefrischt.',
          'Mit dem Klick auf "Verstanden, weiter gehts!" wird dein Account automatisch umgestellt. Nutzer mit unlimitierten Worten können natürlich weiterhin monatlich unlimitiert Texte generieren.',
        ];
      default:
        return [
          'Ab sofort berechnen wir keine einzelnen Worte mehr, sondern ganze Texte. Diese werden täglich wieder aufgefrischt.',
          'Bitte überprüfe deinen Plan für weitere Details.'
        ];
    }
  };

  // Function to get title based on user subscription tier
  const getTitleByTier = (tier: string): string => {
    switch (tier) {
      case 'Kostenlos':
        return `Robowriter lässt dich jetzt noch mehr Texte generieren!`;
      case 'Starter':
        return `Robowriter lässt dich jetzt noch mehr Texte generieren, immer mit dem besten Modell!`;
      case 'Creator':
        return `Update von Robowriter - mehr Texte, mehr Flexibilität!`;
      default:
        return `Robowriter lässt dich jetzt noch mehr Texte generieren!`;
    }
  };

  return (
    <GenericModal
    // TODO: Remove X button
      isOpen={showModal}
      title={getTitleByTier(userTier)}
      content={getContentByTier(userTier)}
      buttonText="Verstanden, weiter geht's!"
      image="/assets/images/writing_hand.svg"
      imagePlacement="top"
      onClose={() => { }}
      onButtonClick={handleButtonClick}
      closeModal={() => { }}
      isLoading={isLoading} // Pass isLoading to GenericModal
      showCloseButton={false} // Hide close button
    />
  )
}

export default CreationsTransitionModal;