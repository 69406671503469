// UserTrackingUtils.ts
import { usePostHog } from 'posthog-js/react';
import { useSearchParams } from 'react-router-dom';
import { auth } from '../config/fbConfig';

export function useDistinctIdFromUrl() {
    const [searchParams] = useSearchParams();
    return searchParams.get('distinct_id');
}

export function useHandleUserIdentification(distinctId: string | null) {
    const posthog = usePostHog();
    if (distinctId) {
        // If the user is logged in, alias the distinctId to the user id
        if (auth?.currentUser) {
            posthog.alias(auth.currentUser.uid, distinctId);
        } 
        // If the user is not logged in, identify them with the distinctId
        else {
            posthog.identify(distinctId);
        }
    }
}

export function appendDistinctIdToUrl(url: string, distinctId: string | null) {
    if (distinctId) {
        const separator = url.includes('?') ? '&' : '?';
        return `${url}${separator}distinct_id=${distinctId}`;
    }
    return url;
}
