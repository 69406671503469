const PlanCard = ({
  title2,
  price,
  discountedPrice,
  planIncludes,
  enabled,
  mostPopular,
  id,
  createUserAndCheckout,
  words,
  CTA,
}: {
  title2: any;
  price: number;
  discountedPrice?: number;
  planIncludes: string[];
  enabled: boolean;
  mostPopular: boolean;
  id: string;
  createUserAndCheckout?: any;
  words: any;
  CTA: string;
}) => {
  const isDiscounted = discountedPrice !== undefined && discountedPrice < price;

  return (
    <div className="bg-white rounded-[10px] shadow-[0px_10px_60px_0px_rgba(38,45,118,0.08)] sm:p-[54px_30px] p-[54px_14px] relative">
      {mostPopular && (
        <div className="text-center absolute top-[7px] left-1/2 -translate-x-1/2 -translate-y-1/2">
          <img src="assets/images/badge.svg" alt="" className="inline-block" />
          <span className="absolute inset-0 xl:text-[15px] text-[10px]  font-[900] uppercase text-white xl:pt-[13px] pt-[8px] inline-flex items-center justify-center">
            Am beliebtesten
          </span>
        </div>
      )}

      <h3 className="text-center font-bold text-[30px] mb-[10px]">{title2?.name}</h3>
      <div className="flex justify-center items-baseline mb-[10px]">
        <h4 className="text-[65px] text-[#1754FC] font-medium leading-[1]">
          {isDiscounted ? `${discountedPrice}€` : `${price}€`}
        </h4>
        {isDiscounted && (
          <span className="ml-2 text-[30px] text-gray-400 line-through">{price}€</span>
        )}
      </div>
      {isDiscounted && (
        <p className="text-center text-[18px] text-green-600 font-semibold mb-[10px]">
          Spare {((price - discountedPrice) / price * 100).toFixed(0)}%
        </p>
      )}
      <h5 className="text-center text-[15px] text-[#B4B4C4]">
        {enabled === true ? "pro Jahr" : "pro Monat"}
      </h5>
      <hr className="border-[#E9E9E9] my-[30px]" />
      <ul className="lg:mb-[54px] mb-[30px]">
        {planIncludes.map((planIncludes, index) => (
          <li key={`plan-include-${index}`} className="leading-[125%] mb-[20px] flex gap-[10px]">
            <i>
              <img src="assets/images/check.svg" alt="" />
            </i>
            <h5 className="flex-1 inline-block text-[#414143]">{planIncludes}</h5>
          </li>
        ))}
      </ul>
      <button
        onClick={() => createUserAndCheckout(id, words)}
        type="button"
        className="w-full inline-flex justify-center items-center px-6 py-3 border border-transparent text-base font-medium rounded-[8px] shadow-sm text-white bg-secondary-500 hover:bg-secondary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500 transition duration-300 ease-in-out transform hover:scale-105"
      >
        {CTA}
      </button>
      <p className="text-center text-[#B4B4C4] text-[15px] capitalize mt-[14px]">
        Jederzeit kündbar - Mwst. inklusive
      </p>
    </div>
  );
};

export default PlanCard;